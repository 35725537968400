import React from 'react';
import { clsx } from 'clsx';
import { useSelector } from 'react-redux';

import { useHandleClickOutside } from '../../hooks/useHandleClickOutside';
import { RootState } from '../../redux/store';
import { Accordion } from '../Accordion';

import { getSourcesFromResponse } from './utils/getSourcesFromResponse';
import { Hypotheses } from './Hypotheses';
import { SourcesList } from './SourcesList';
import { SQLQuery } from './SQLQuery';

import styles from './ViewSourceReasoning.module.css';

interface Props {
  onClose: () => void;
}

const ViewSourceReasoning: React.FC<Props> = ({ onClose }) => {
  const viewSourceState = useSelector((state: RootState) => state.conversation.viewSourceState);
  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);

  const viewSourceRef = useHandleClickOutside<HTMLDivElement>({ onClickOutside: onClose });
  const answerNode = viewSourceState?.answerNodeId ? conversationTree?.nodes[viewSourceState.answerNodeId] : null;

  if (!viewSourceState?.show || !answerNode) {
    return null;
  }

  const response = answerNode.data.engine_response?.response ?? [];
  const allSources = getSourcesFromResponse(answerNode.data.engine_response);
  const externalSources = allSources.filter((source) => source.type === 'url');
  const internalSources = allSources.filter((source) => source.type !== 'url');

  const allSubHeadings = response.slice(1, -1);
  const reasoningSubHeadings = allSubHeadings.filter((subHeading) =>
    subHeading.citations?.some((citation) => typeof citation !== 'string' && citation?.mode === 'reasoning'),
  );

  return (
    <div className={styles.root} ref={viewSourceRef}>
      <div className={clsx(styles.section, styles.headerContainer)}>
        <div className={styles.header}>Sources</div>
        <button className={styles.iconButton} onClick={onClose}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              className="svg-icon-path"
              d="M11.8334 1.34166L10.6584 0.166656L6.00002 4.82499L1.34169 0.166656L0.166687 1.34166L4.82502 5.99999L0.166687 10.6583L1.34169 11.8333L6.00002 7.17499L10.6584 11.8333L11.8334 10.6583L7.17502 5.99999L11.8334 1.34166Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      {externalSources.length > 0 || internalSources.length > 0 ? (
        <div className={styles.section}>
          <Accordion
            items={[
              externalSources.length > 0 && {
                title: <div className={styles.sourceAccordionHeading}>External ( {externalSources.length} )</div>,
                content: <SourcesList sources={externalSources} />,
              },
              internalSources.length > 0 && {
                title: <div className={styles.sourceAccordionHeading}>Internal ( {internalSources.length} )</div>,
                content: <SourcesList sources={internalSources} />,
              },
            ]}
            multiActive
            initialActiveIndexes={[0, 1]}
          />
        </div>
      ) : null}
      <div className={styles.section}>
        {reasoningSubHeadings.length > 0 ? (
          <Hypotheses subHeadings={reasoningSubHeadings} />
        ) : (
          answerNode.data.engine_response?.sql_query &&
          answerNode.data.engine_response?.sql_query !== 'None' && (
            <div>
              <div className={styles.queryHeading}>Associated SQL Query</div>
              <SQLQuery query={answerNode.data.engine_response?.sql_query} />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ViewSourceReasoning;
