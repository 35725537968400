import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSharedUserProfile, updateUserEmails } from '../../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { ApiResponse } from '../../../services/apiService/definitions/types';

type Props = {
  isChatOwner?: boolean;
  nodeEmail?: string;
};

const UserAvatar: React.FC<Props> = ({ isChatOwner, nodeEmail }) => {
  const dispatch = useDispatch<AppDispatch>();
  const userEmails = useSelector((state: RootState) => state.conversation.userEmails);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const defaultImage = `${process.env.PUBLIC_URL}/images/user-image.png`;
  const userImage = useSelector((state: RootState) => state.user.userData?.image);

  useEffect(() => {
    // Cleanup function to clear localStorage on tab close or reload
    const handleBeforeUnload = () => {
      localStorage.removeItem('inFlightRequests');
    };

    // Add the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!isChatOwner && nodeEmail) {
      const email = userEmails.find((email) => email.email === nodeEmail);
      const isEmptyBlob = email && email.image === '';

      // Retrieve in-flight requests from localStorage
      const inFlightRequests = JSON.parse(localStorage.getItem('inFlightRequests') || '[]');

      if (!email?.image || isEmptyBlob) {
        if (inFlightRequests.includes(nodeEmail)) return; // Skip if request already in progress

        // Add to localStorage in-flight requests
        const updatedRequests = [...inFlightRequests, nodeEmail];
        localStorage.setItem('inFlightRequests', JSON.stringify(updatedRequests));

        dispatch(fetchSharedUserProfile({ userEmail: nodeEmail })).then((result) => {
          if (result.type.endsWith('fulfilled')) {
            const apiResponse = result.payload as ApiResponse<string>;
            if (apiResponse.data) {
              const imageBlob = new Blob([apiResponse.data], { type: 'image/png' });
              const url = URL.createObjectURL(imageBlob);
              setImageUrl(url);

              // Update the userEmails array immutably
              const updatedEmails = email
                ? userEmails.map((userEmail) =>
                    userEmail.email === nodeEmail ? { ...userEmail, image: url } : userEmail,
                  )
                : [...userEmails, { email: nodeEmail, image: url }];
              dispatch(updateUserEmails({ userEmails: updatedEmails }));
            }
          }

          // Remove the email from in-flight requests in localStorage
          const finalRequests = JSON.parse(localStorage.getItem('inFlightRequests') || '[]').filter(
            (e: string) => e !== nodeEmail,
          );
          localStorage.setItem('inFlightRequests', JSON.stringify(finalRequests));
        });
      } else if (email?.image && !isEmptyBlob) {
        setImageUrl(email.image);
      }
    } else {
      setImageUrl(userImage ?? null);
    }
  }, [isChatOwner, nodeEmail, userEmails, dispatch, userImage]);

  return (
    <img
      src={imageUrl || defaultImage}
      alt="profile"
      className="profImg"
      loading="lazy"
      onError={(e) => {
        const imgElement = e.target as HTMLImageElement;
        imgElement.src = defaultImage;
      }}
    />
  );
};

export default UserAvatar;
