import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';

import { Env } from '../config/env';
import { RootState } from '../redux/store';

const EVENTS = {
  CHAT_SUBMITTED: 'chat_submitted',
  CHART_TOGGLE: 'chart_toggle',
  CHAT_RESPONSE_RECEIVED: 'chat_response_received',
  NEW_CHAT_CLICK: 'track_new_chat',
  ONBOARDING_STEPS: 'track_onboarding_step',
  ONBOARDING_COMPLETE: 'track_onboarding_complete',
  FEEDBACK_SUBMIT: 'track_feedback_submit',
  CACHE_TOGGLE: 'cache_toggle',
  SEMANTIC_CACHE_TOGGLE: 'semantic_cache_toggle',
  THEME_BUTTON_CLICK: 'theme_button_click',
  STOP_QUESTION_CLICK: 'stop_question_click',
  RETRY_CLICK: 'retry_click',
  DOMAIN_DROPDOWN_CLICK: 'domain_dropdown_click',
  DOMAIN_CLICK: 'domain_click',
  TUTORIAL_BUTTON_CLICK: 'tutorial_button_click',
  PROMPT_GUIDE_CLICK: 'prompt_guide_click',
  MAXIMIZE_CLICK: 'maximize_click',
  MINIMIZE_CLICK: 'minimize_click',
  DOWNLOAD_CLICK: 'download_click',
  CHART_TYPE_CHANGE: 'chart_type_change',
  CHART_VISIBILITY_TOGGLE: 'chart_visibility_toggle',
  CHAT_HISTORY_CLICK: 'chat_history_click',
  SHARE_BUTTON_CLICK: 'share_button_click',
  SHARE_COPY_LINK_BUTTON_CLICK: 'share_copy_link_button_click',
  SHARE_MAIL_POPUP_CLICK: 'share_mail_popup_click',
  SHARE_DOWNLOAD_PDF_BUTTON_CLICK: 'share_download_pdf_button_click',
  SHARE_MAIL_DISPATCH_BUTTON_CLICK: 'share_mail_dispatch_button_click',
};

const ENVIRONMENT = {
  LOCAL: 'local',
  DEV: 'dev',
  STAGING: 'staging',
  PROD: 'prod',
};
if (!Env.APP_ENV) {
  Env.APP_ENV = 'local';
}
const isLocal = Env.APP_ENV == ENVIRONMENT.LOCAL;

let initialized = false;

const useAnalytics = () => {
  const currentProjectKey = useSelector((state: RootState) => state.sideBar.projectKey);

  // Initialization
  useEffect(() => {
    if (!isLocal && !initialized) {
      const isDev = Env.APP_ENV === ENVIRONMENT.DEV;
      mixpanel.init(Env.MIXPANEL_TOKEN || '', { debug: isDev, ignore_dnt: true, record_sessions_percent: 80 });

      // We will move it to env file at some point.
      datadogRum.init({
        applicationId: 'a24679d6-1cbe-491b-abe9-03dcc9ef6cb2',
        clientToken: Env.DATADOG_RUM_TOKEN || '',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.com',
        service: 'insights-copilot-ui',
        env: isDev ? 'dev' : 'staging',
        allowedTracingUrls: [
          'https://api.insights-copilot.dev.ab-inbev.com',
          'https://api.insights-copilot.staging.ab-inbev.com',
          /https:\/\/.*\.ab-inbev\.com/,
          (url) => url.startsWith('https://insights-copilot'),
        ],
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
      });

      initialized = true;
    }
  }, []);

  // Identify Users
  const identifyUser = (userId: any, userProperties = {}) => {
    if (!isLocal) {
      mixpanel.identify(userId);
      mixpanel.people.set(userProperties);
      ReactGA.set({ userId });

      datadogRum.setUser({
        email: userId,
      });
    }
  };

  const trackMixpanelEvent = (event: string, properties: any) => {
    if (!isLocal) {
      mixpanel.track(event, { ...properties, currentProjectKey });
    } else {
      console.log('MIXPANEL Event:', event, 'Properties:', { ...properties, currentProjectKey });
    }
  };

  const trackChatSubmitEvent = (chatSubmitProps = {}) => {
    trackMixpanelEvent(EVENTS.CHAT_SUBMITTED, chatSubmitProps);
  };

  const trackChartToggle = (toggle: boolean) => {
    trackMixpanelEvent(EVENTS.CHART_TOGGLE, { mode: toggle ? 'data_table' : 'chart' });
  };

  const trackChatResponseReceived = (chatProperties: any) => {
    trackMixpanelEvent(EVENTS.CHAT_RESPONSE_RECEIVED, chatProperties);
  };

  const trackNewChatButtonClick = () => {
    trackMixpanelEvent(EVENTS.NEW_CHAT_CLICK, {});
  };

  const trackOnboardingStep = (step: number) => {
    trackMixpanelEvent(EVENTS.ONBOARDING_STEPS, { step });
  };

  const trackOnboardingComplete = () => {
    trackMixpanelEvent(EVENTS.ONBOARDING_COMPLETE, {});
  };

  const trackFeedbackSubmit = (chat_id: string, selectedOptions: Array<string>) => {
    trackMixpanelEvent(EVENTS.FEEDBACK_SUBMIT, { chat_id, options: selectedOptions });
  };

  const trackCacheToggle = (toggle: boolean) => {
    trackMixpanelEvent(EVENTS.CACHE_TOGGLE, { mode: toggle ? 'cache_enable' : 'cache_disable' });
  };

  const trackSemanticCacheToggle = (toggle: boolean) => {
    trackMixpanelEvent(EVENTS.SEMANTIC_CACHE_TOGGLE, {
      mode: toggle ? 'semantic_cache_enable' : 'semantic_cache_disable',
    });
  };

  const trackThemeButtonClick = () => {
    trackMixpanelEvent(EVENTS.THEME_BUTTON_CLICK, {});
  };

  const trackStopQuestionClick = (chatId: string, conversationId: string) => {
    trackMixpanelEvent(EVENTS.STOP_QUESTION_CLICK, { chatId, conversationId });
  };

  const trackRetryClick = (project_key: string, conversationId: string) => {
    trackMixpanelEvent(EVENTS.RETRY_CLICK, { project_key, conversationId });
  };

  const trackDomainDropdownClick = () => {
    trackMixpanelEvent(EVENTS.DOMAIN_DROPDOWN_CLICK, {});
  };

  const trackDomainClick = (project_key: string) => {
    trackMixpanelEvent(EVENTS.DOMAIN_CLICK, { project_key });
  };

  const trackTutorialButtonClick = () => {
    trackMixpanelEvent(EVENTS.TUTORIAL_BUTTON_CLICK, {});
  };

  const trackPromptGuideClick = () => {
    trackMixpanelEvent(EVENTS.PROMPT_GUIDE_CLICK, {});
  };

  const trackMaximizeClick = (chartType: string) => {
    trackMixpanelEvent(EVENTS.MAXIMIZE_CLICK, { chartType });
  };

  const trackMinimizeClick = (chartType: string) => {
    trackMixpanelEvent(EVENTS.MINIMIZE_CLICK, { chartType });
  };

  const trackDownloadClick = (chartType: string, position: string) => {
    trackMixpanelEvent(EVENTS.DOWNLOAD_CLICK, { chartType, position });
  };

  const trackChartTypeChange = (chartType: string) => {
    trackMixpanelEvent(EVENTS.CHART_TYPE_CHANGE, { chartType });
  };

  const trackChartVisibilityToggle = (showChart: boolean) => {
    trackMixpanelEvent(EVENTS.CHART_VISIBILITY_TOGGLE, { showChart });
  };

  const trackChatHistoryClick = (conversation_id: string) => {
    trackMixpanelEvent(EVENTS.CHAT_HISTORY_CLICK, { conversation_id });
  };

  const trackShareButtonClick = (conversation_id: string | null) => {
    trackMixpanelEvent(EVENTS.SHARE_BUTTON_CLICK, { conversation_id });
  };

  const trackShareCopyLinkClick = (conversation_id: string | null) => {
    trackMixpanelEvent(EVENTS.SHARE_COPY_LINK_BUTTON_CLICK, { conversation_id });
  };

  const trackShareMailPopupClick = (conversation_id: string | null) => {
    trackMixpanelEvent(EVENTS.SHARE_MAIL_POPUP_CLICK, { conversation_id });
  };

  const trackShareDownloadPDFClick = (conversation_id: string | null) => {
    trackMixpanelEvent(EVENTS.SHARE_DOWNLOAD_PDF_BUTTON_CLICK, { conversation_id });
  };

  const trackShareMailDispatchClick = (conversation_id: string | null) => {
    trackMixpanelEvent(EVENTS.SHARE_MAIL_DISPATCH_BUTTON_CLICK, { conversation_id });
  };

  return {
    identifyUser,
    trackChatSubmitEvent,
    trackChartToggle,
    trackChatResponseReceived,
    trackNewChatButtonClick,
    trackOnboardingStep,
    trackOnboardingComplete,
    trackFeedbackSubmit,
    trackCacheToggle,
    trackSemanticCacheToggle,
    trackThemeButtonClick,
    trackStopQuestionClick,
    trackRetryClick,
    trackDomainDropdownClick,
    trackDomainClick,
    trackTutorialButtonClick,
    trackPromptGuideClick,
    trackMaximizeClick,
    trackMinimizeClick,
    trackDownloadClick,
    trackChartTypeChange,
    trackChartVisibilityToggle,
    trackChatHistoryClick,
    trackShareButtonClick,
    trackShareCopyLinkClick,
    trackShareMailPopupClick,
    trackShareDownloadPDFClick,
    trackShareMailDispatchClick,
  };
};

export default useAnalytics;
