import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useAnalytics from '../../hooks/useAnalytics';
import { setIsCacheEnabled, setIsSemanticCacheEnabled } from '../../redux/features/conversationSlice';
import { setIsSettingsOpen } from '../../redux/features/userSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { logout } from '../../services/apiService';
import { logoutAndCleanup } from '../../utils/auth';
import SelectMenu from '../Core/SelectMenu/selectMenu';
import Tooltip from '../Core/Tooltip/Tooltip';

import DeleteConfirmationPopup from './DeleteConfirmationPopup';

import './Settings.css';
import styles from '../../components/Core/Toggle/ChartDataToggle.module.css';

const SettingsContainer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();

  const [ShowDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const theme = useSelector((state: RootState) => state.user.theme);
  const isSettingsOpen = useSelector((state: RootState) => state.user.isSettingsOpen);
  const isCacheEnabled = useSelector((state: RootState) => state.conversation.isCacheEnabled);
  const isSemanticCacheEnabled = useSelector((state: RootState) => state.conversation.isSemanticCacheEnabled);
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);
  const semanticCacheCheckboxRef = useRef<any>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      dispatch(setIsSettingsOpen(false));
    }
  };

  const handleCacheClick = () => {
    dispatch(setIsCacheEnabled());
    analytics.trackCacheToggle(!isCacheEnabled);
    window.localStorage.setItem('isCacheEnabled', JSON.stringify(!isCacheEnabled));
    // Trigger Semantic Cache checkbox click only if it's not already enabled
    // We are doing this to toggle css knobs layer to change
    // if cache :false-> semantic_cache:false
    // if cache :true -> semantic_cache: true or false
    if (isSemanticCacheEnabled && semanticCacheCheckboxRef.current) {
      semanticCacheCheckboxRef.current.click();
    }
  };

  const handleSementicCacheClick = () => {
    dispatch(setIsSemanticCacheEnabled());
    analytics.trackSemanticCacheToggle(!isSemanticCacheEnabled);
    window.localStorage.setItem('isSemanticCacheEnabled', JSON.stringify(!isSemanticCacheEnabled));
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleLogoutClick = () => {
    logout().finally(() => {
      logoutAndCleanup();
      navigate('/');
    });
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const customStyles = {
    width: '260px',
    zIndex: 2,
    fontSize: 'var(--Info-12)',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    padding: 'var(--s)',
    top: '15px',
    left: '-1px',
    bottom: 'auto',
  };

  const cacherightspace = {
    width: '260px',
    zIndex: 2,
    fontSize: 'var(--Info-12)',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    padding: 'var(--s)',
    top: '15px',
    left: '-60px',
    bottom: 'auto',
  };
  return (
    <>
      {isSettingsOpen && (
        <div className="setting-container">
          <div className="settings-menu" ref={menuRef}>
            <div className="settings-option" style={{ display: 'flex' }}>
              <span className={styles.switch_wrap} style={{ justifyContent: 'space-between', width: '100%' }}>
                <p
                  className={styles.firstLabel}
                  style={{
                    fontSize: `var(--Paragraph-16)`,
                    fontWeight: '600',
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                  }}
                >
                  Cache{' '}
                  <Tooltip
                    tooltipText="Get instant answers to repeated questions by storing previous queries for quick access."
                    classname={customStyles}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <mask id="mask0_3556_29623" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
                        <rect width="12" height="12" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_3556_29623)">
                        <path
                          d="M5.5 8.5H6.5V5.5H5.5V8.5ZM6 4.5C6.14167 4.5 6.26042 4.45208 6.35625 4.35625C6.45208 4.26042 6.5 4.14167 6.5 4C6.5 3.85833 6.45208 3.73958 6.35625 3.64375C6.26042 3.54792 6.14167 3.5 6 3.5C5.85833 3.5 5.73958 3.54792 5.64375 3.64375C5.54792 3.73958 5.5 3.85833 5.5 4C5.5 4.14167 5.54792 4.26042 5.64375 4.35625C5.73958 4.45208 5.85833 4.5 6 4.5ZM6 11C5.30833 11 4.65833 10.8687 4.05 10.6062C3.44167 10.3438 2.9125 9.9875 2.4625 9.5375C2.0125 9.0875 1.65625 8.55833 1.39375 7.95C1.13125 7.34167 1 6.69167 1 6C1 5.30833 1.13125 4.65833 1.39375 4.05C1.65625 3.44167 2.0125 2.9125 2.4625 2.4625C2.9125 2.0125 3.44167 1.65625 4.05 1.39375C4.65833 1.13125 5.30833 1 6 1C6.69167 1 7.34167 1.13125 7.95 1.39375C8.55833 1.65625 9.0875 2.0125 9.5375 2.4625C9.9875 2.9125 10.3438 3.44167 10.6062 4.05C10.8687 4.65833 11 5.30833 11 6C11 6.69167 10.8687 7.34167 10.6062 7.95C10.3438 8.55833 9.9875 9.0875 9.5375 9.5375C9.0875 9.9875 8.55833 10.3438 7.95 10.6062C7.34167 10.8687 6.69167 11 6 11ZM6 10C7.11667 10 8.0625 9.6125 8.8375 8.8375C9.6125 8.0625 10 7.11667 10 6C10 4.88333 9.6125 3.9375 8.8375 3.1625C8.0625 2.3875 7.11667 2 6 2C4.88333 2 3.9375 2.3875 3.1625 3.1625C2.3875 3.9375 2 4.88333 2 6C2 7.11667 2.3875 8.0625 3.1625 8.8375C3.9375 9.6125 4.88333 10 6 10Z"
                          fill="white"
                          fillOpacity="0.23"
                        />
                      </g>
                    </svg>
                  </Tooltip>
                </p>
                <label className={styles.switch} style={{ marginRight: 0 }}>
                  <input type="checkbox" checked={isCacheEnabled} onChange={handleCacheClick} />
                  <span className={` ${isCacheEnabled ? `${styles.active}` : `${styles.inactive}`}`}></span>
                </label>
              </span>
            </div>
            <div className="line" style={{ marginTop: 0 }}></div>
            <div className="settings-option" style={{ display: 'flex' }}>
              <span className={styles.switch_wrap} style={{ justifyContent: 'space-between', width: '100%' }}>
                <p
                  className={styles.firstLabel}
                  style={{
                    fontSize: `var(--Paragraph-16)`,
                    fontWeight: '600',
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                  }}
                >
                  Semantic Cache
                  <Tooltip
                    tooltipText="Enjoy faster responses to similar questions with semantic cache, which understands the context and nuances of your queries."
                    classname={cacherightspace}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <mask id="mask0_3556_29623" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
                        <rect width="12" height="12" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_3556_29623)">
                        <path
                          d="M5.5 8.5H6.5V5.5H5.5V8.5ZM6 4.5C6.14167 4.5 6.26042 4.45208 6.35625 4.35625C6.45208 4.26042 6.5 4.14167 6.5 4C6.5 3.85833 6.45208 3.73958 6.35625 3.64375C6.26042 3.54792 6.14167 3.5 6 3.5C5.85833 3.5 5.73958 3.54792 5.64375 3.64375C5.54792 3.73958 5.5 3.85833 5.5 4C5.5 4.14167 5.54792 4.26042 5.64375 4.35625C5.73958 4.45208 5.85833 4.5 6 4.5ZM6 11C5.30833 11 4.65833 10.8687 4.05 10.6062C3.44167 10.3438 2.9125 9.9875 2.4625 9.5375C2.0125 9.0875 1.65625 8.55833 1.39375 7.95C1.13125 7.34167 1 6.69167 1 6C1 5.30833 1.13125 4.65833 1.39375 4.05C1.65625 3.44167 2.0125 2.9125 2.4625 2.4625C2.9125 2.0125 3.44167 1.65625 4.05 1.39375C4.65833 1.13125 5.30833 1 6 1C6.69167 1 7.34167 1.13125 7.95 1.39375C8.55833 1.65625 9.0875 2.0125 9.5375 2.4625C9.9875 2.9125 10.3438 3.44167 10.6062 4.05C10.8687 4.65833 11 5.30833 11 6C11 6.69167 10.8687 7.34167 10.6062 7.95C10.3438 8.55833 9.9875 9.0875 9.5375 9.5375C9.0875 9.9875 8.55833 10.3438 7.95 10.6062C7.34167 10.8687 6.69167 11 6 11ZM6 10C7.11667 10 8.0625 9.6125 8.8375 8.8375C9.6125 8.0625 10 7.11667 10 6C10 4.88333 9.6125 3.9375 8.8375 3.1625C8.0625 2.3875 7.11667 2 6 2C4.88333 2 3.9375 2.3875 3.1625 3.1625C2.3875 3.9375 2 4.88333 2 6C2 7.11667 2.3875 8.0625 3.1625 8.8375C3.9375 9.6125 4.88333 10 6 10Z"
                          fill="white"
                          fillOpacity="0.23"
                        />
                      </g>
                    </svg>
                  </Tooltip>
                </p>
                <label className={styles.switch} style={{ marginRight: 0 }}>
                  <input
                    type="checkbox"
                    ref={semanticCacheCheckboxRef}
                    checked={isSemanticCacheEnabled}
                    onChange={handleSementicCacheClick}
                  />
                  <span className={` ${isSemanticCacheEnabled ? `${styles.active}` : `${styles.inactive}`}`}></span>
                </label>
              </span>
            </div>
            <div className="line" style={{ marginTop: 0 }}></div>
            <div className="settings-option" style={{ display: 'flex' }}>
              <p style={{ fontSize: `var(--Paragraph-16)`, fontWeight: '600', flexGrow: 1 }}>Theme</p>
              <SelectMenu />
            </div>
            <div className="line" style={{ marginTop: 0 }}></div>
            <div className="settings-option" style={{ display: 'flex' }}>
              <p style={{ fontSize: `var(--Paragraph-16)`, fontWeight: '600', flexGrow: 1 }}>Clear all chat history</p>
              <div
                className="delete-button"
                onClick={(e) => {
                  e.stopPropagation();

                  handleDelete();
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M9.33317 5.25V11.0833H4.6665V5.25H9.33317ZM8.45817 1.75H5.5415L4.95817 2.33333H2.9165V3.5H11.0832V2.33333H9.0415L8.45817 1.75ZM10.4998 4.08333H3.49984V11.0833C3.49984 11.725 4.02484 12.25 4.6665 12.25H9.33317C9.97484 12.25 10.4998 11.725 10.4998 11.0833V4.08333Z"
                    fill={theme === 'light-theme' ? '#fff' : '#FF6F65'}
                  />
                </svg>
                Delete
              </div>
            </div>
            <div className="line" style={{ marginTop: 0 }}></div>
            <div
              className="settings-option"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 'var(--XS, 8px)',
                color: 'var(--Secondary-Red-500)',
                fontWeight: '600',
                cursor: 'pointer',
              }}
              onClick={handleLogoutClick}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M12.75 6L11.6925 7.0575L12.8775 8.25H6.75V9.75H12.8775L11.6925 10.935L12.75 12L15.75 9L12.75 6ZM3.75 3.75H9V2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H9V14.25H3.75V3.75Z"
                  fill="#FF3236"
                />
              </svg>
              Logout
            </div>
          </div>
        </div>
      )}
      {ShowDeleteConfirmation && <DeleteConfirmationPopup setShowDeleteConfirmation={setShowDeleteConfirmation} />}
    </>
  );
};

export default SettingsContainer;
