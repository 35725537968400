import React, { FC, useEffect, useRef, useState } from 'react';

import useAnalytics from '../../hooks/useAnalytics';
import { EngineChartResults, ParsedReasoningEngineCitation } from '../../services/apiService/definitions/types';
import { downloadChart, downloadTable, makeKeysForSheets, sanitizeForChartTitle } from '../../utils/utils';
import Tooltip from '../Core/Tooltip/Tooltip';
import {
  AccordionDownIcon,
  AccordionUpIcon,
  ChartIcon,
  DownloadIcon,
  MaximizeIcon,
  MinimizeIcon,
  TableIcon,
} from '../icons/ChartIcons';
import GridExample from '../Table/table';
import { Tabs } from '../Tabs';

import ChartComponent, { ChartType } from './ChartComponent';

import styles from './RenderCard.module.css';

type Props = {
  chartResults?: EngineChartResults | null;
  sqlResult?: Record<string, unknown>[];
  chat_name?: string;
  data?: ParsedReasoningEngineCitation[];
  colorCodingColumns?: string[] | null;
};

type FigureType = 'table' | 'chart';

type DisplayType = 'tabs' | 'single' | 'none';

type FigureTitle = {
  chart: string;
  table: string;
};

const RenderChartDataCard = ({ chartResults, sqlResult, chat_name, data, colorCodingColumns }: Props) => {
  const analytics = useAnalytics();
  const [showChart, setShowChart] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(true);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  // Shared state for active tab index
  const [activeIndexTable, setActiveIndexTable] = useState<number>(0);
  const [activeIndexChart, setActiveIndexChart] = useState<number>(0);

  const parentRef = useRef<HTMLDivElement | null>(null);

  const [parentWidth, setParentWidth] = useState(0);

  const isComplexQuery = data && data.length > 0;

  const filteredDataCharts = data?.filter(
    (citation) => citation?.chart_results?.chart_data?.length && citation?.chart_results?.chart_data?.length >= 1,
  );

  const filteredDataTables = data?.filter(
    (citation) => citation?.sql_result?.length && citation?.sql_result?.length > 0,
  );

  const updateParentWidth = () => {
    if (parentRef.current) {
      const width = parentRef.current.getBoundingClientRect().width;
      setParentWidth(width); // Update state with parent's width
    }
  };
  useEffect(() => {
    // Initial width calculation
    updateParentWidth();

    // Recalculate width on window resize
    window.addEventListener('resize', updateParentWidth);
    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);

  const [selectedChartType, setSelectedChartType] = useState<ChartType>(
    (isComplexQuery && filteredDataCharts
      ? (filteredDataCharts[activeIndexChart]?.chart_results?.chart_type as ChartType)
      : (chartResults?.chart_type as ChartType | null)) ?? 'line',
  );

  const figureTitles = useRef<FigureTitle>({ chart: '', table: '' });

  const containerStyle = {
    maxWidth: showPopup
      ? '1000px'
      : selectedChartType === 'pie' || selectedChartType === 'doughnut'
        ? '600px'
        : `${parentWidth}px`,
    //maxHeight: showPopup ? '480px' : 'none',
  };

  const chartRefs = {
    line: useRef<any>(null),
    vbar: useRef<any>(null),
    hbar: useRef<any>(null),
    doughnut: useRef<any>(null),
    pie: useRef<any>(null),
    area: useRef<any>(null),
    stackedbar: useRef<any>(null),
    stackedarea: useRef<any>(null),
  };

  const getChartTitle = (type: FigureType) => {
    let chartTitle;
    if (isComplexQuery) {
      chartTitle = data[0]?.answer_key;
    } else {
      chartTitle = chartResults?.chart_title;
    }
    if (chartTitle) {
      return sanitizeForChartTitle(chartTitle);
    }

    return type === 'chart' ? 'Chart' : 'Table';
  };

  // Combined function to render a button or an accordion arrow with a tooltip
  const renderIconButton = (
    tooltipText: string,
    onClick: () => void,
    iconSvg: React.ReactNode,
    additionalClass?: string,
  ) => (
    <Tooltip tooltipText={tooltipText} position="right">
      <div className={`${styles.control_common_button_bc} ${additionalClass || ''}`} onClick={onClick}>
        {iconSvg}
      </div>
    </Tooltip>
  );

  const handleDownloadTable = () => {
    let feedData;
    if (isComplexQuery) {
      feedData = filteredDataTables?.reduce((acc, citation, index) => {
        const chartTitle = citation?.chart_results?.chart_title ?? `Table ${index + 1}`;
        const key = makeKeysForSheets(chartTitle, acc);

        return { ...acc, [key]: citation.sql_result };
      }, {});
    } else {
      feedData = sqlResult;
    }

    const chartTitle = getChartTitle('table');
    downloadTable(feedData, chartTitle);
  };

  const handleDownloadChart = () => {
    const chartTitle = getChartTitle('chart');

    // TODO: Trigger group download when multiple tabs available
    // Use UI rendered ref to create png
    let chartType: ChartType;
    if (filteredDataCharts && isComplexQuery) {
      if (filteredDataCharts.length === 1) {
        chartType = (filteredDataCharts[0]?.chart_results?.chart_type as ChartType) ?? 'line';
      } else {
        chartType = (filteredDataCharts[activeIndexChart]?.chart_results?.chart_type as ChartType) ?? 'line';
      }
    } else {
      chartType = selectedChartType;
    }
    downloadChart(chartRefs[chartType]?.current, chartTitle);
  };

  // Use the combined function for both button and accordion arrow
  const renderControlButtons = (type: FigureType, isOpen: boolean, toggleAccordion: () => void) => {
    return (
      <>
        {/* Render Accordion Arrow */}
        {!showPopup &&
          renderIconButton(
            isOpen ? 'Collapse' : 'Expand',
            toggleAccordion,
            isOpen ? <AccordionDownIcon /> : <AccordionUpIcon />,
            styles.accordionArrowButton,
          )}
        {/* Render Download Button */}
        {renderIconButton('Download', type === 'table' ? handleDownloadTable : handleDownloadChart, <DownloadIcon />)}

        {/* Render Preview/Minimize Button */}
        {!showPopup
          ? renderIconButton('Maximize', () => handleMaximize(type), <MaximizeIcon />)
          : renderIconButton('Minimize', () => handleMinimize(type), <MinimizeIcon />)}
      </>
    );
  };

  const renderChartDataContainer = (
    content: React.ReactNode,
    type: FigureType,
    isOpen: boolean,
    toggleAccordion: () => void,
  ) => (
    <div
      className={`${styles.chart_data_container} ${type === 'table' ? styles.table_data_container : ''}`}
      style={containerStyle}
    >
      <div className={styles.chart_data_header}>
        <div className="icon" style={{ display: 'flex', gap: 'var(--xs)', alignItems: 'center' }}>
          {type === 'table' ? <TableIcon /> : <ChartIcon />}
          <span className={styles.chart_data_title}>
            {type === 'table' ? figureTitles.current.table : figureTitles.current.chart}
          </span>
        </div>

        <div className={styles.chart_controls_common_btn} style={{ display: 'flex' }}>
          {renderControlButtons(type, isOpen, toggleAccordion)}
        </div>
      </div>
      {/** Tab bar with tabs */}
      {isOpen && content} {/* Only render content if section is open */}
      {type === 'table' && (
        <div style={{ display: 'none' }}>
          <span></span>
        </div>
      )}
    </div>
  );

  const handleChartTypeChange = (type: ChartType) => {
    analytics.trackChartTypeChange(type);
    setSelectedChartType(type);
  };

  const handleMaximize = (type: FigureType) => {
    setShowPopup(true);
    if (type === 'table') {
      setShowTable(true);
      setShowChart(false); // Ensure chart is hidden when showing the table
    } else {
      setShowChart(true);
      setShowTable(false); // Ensure table is hidden when showing the chart
    }
    analytics.trackMaximizeClick(type === 'table' ? 'table' : selectedChartType);
  };

  const handleMinimize = (type: FigureType) => {
    setShowPopup(false);
    setShowChart(true); // Reset to showing both chart and table
    setShowTable(true);
    analytics.trackMinimizeClick(type == 'table' ? 'table' : selectedChartType);
  };

  const makeTitleForSimpleFigure = (chartResult: EngineChartResults | null, fallbackTitle: string) => {
    const title = chartResult?.chart_title ?? chat_name ?? fallbackTitle;
    return sanitizeForChartTitle(title);
  };

  const makeTitleForTabsFigure = (data: any, fallbackTitle: string) => {
    const title = data[0]?.answer_key ?? fallbackTitle;
    return sanitizeForChartTitle(title);
  };

  const tableTabs = (() => {
    if (isComplexQuery && filteredDataTables) {
      if (filteredDataTables.length === 0) {
        return null;
      }

      if (filteredDataTables.length === 1) {
        figureTitles.current.table = makeTitleForSimpleFigure(data[0].chart_results, 'Table');
        return (
          <GridExample
            result={data[0]?.sql_result as Record<string, unknown>[]}
            columnsChangeList={data[0].change_columns ?? []}
          />
        );
      }

      figureTitles.current.table = makeTitleForTabsFigure(data, 'Table');
      return filteredDataTables?.map((citation, idx) => ({
        label: citation?.chart_results?.chart_title || `Table ${idx + 1}`,
        content: (
          <GridExample
            key={idx}
            result={citation?.sql_result as Record<string, unknown>[]}
            columnsChangeList={citation?.change_columns ?? []}
          />
        ),
      }));
    } else {
      if (!(sqlResult && sqlResult.length > 0)) {
        return null;
      }

      figureTitles.current.table = makeTitleForSimpleFigure(chartResults ?? null, 'Table');
      return (
        <GridExample
          result={Array.isArray(sqlResult) ? (sqlResult ?? []) : []}
          columnsChangeList={colorCodingColumns ?? []}
        />
      );
    }
  })();
  const tableDisplayType: DisplayType = Array.isArray(tableTabs) ? 'tabs' : tableTabs !== null ? 'single' : 'none';

  const chartTabs = (() => {
    if (filteredDataCharts && isComplexQuery) {
      if (filteredDataCharts.length === 0) {
        return null;
      }

      if (filteredDataCharts.length === 1) {
        figureTitles.current.chart = makeTitleForSimpleFigure(filteredDataCharts[0].chart_results, 'Chart');
        return (
          <ChartComponent
            selectedChartType={selectedChartType}
            chartResults={filteredDataCharts[0]?.chart_results}
            chartRefs={chartRefs}
            showPopup={showPopup}
            handleChartTypeChange={handleChartTypeChange}
          />
        );
      }

      figureTitles.current.chart = makeTitleForTabsFigure(filteredDataCharts, 'Chart');
      return filteredDataCharts // Filter based on chart_data length
        ?.map((citation, idx) => ({
          label: citation?.chart_results?.chart_title || `Chart ${idx + 1}`,
          content: (
            <ChartComponent
              selectedChartType={selectedChartType}
              chartResults={citation?.chart_results}
              chartRefs={chartRefs}
              showPopup={showPopup}
              handleChartTypeChange={handleChartTypeChange}
            />
          ),
        }));
    } else {
      if (chartResults?.chart_data?.length && chartResults?.chart_data.length >= 1) {
        figureTitles.current.chart = makeTitleForSimpleFigure(chartResults, 'Chart');
        return (
          <ChartComponent
            selectedChartType={selectedChartType}
            chartResults={chartResults}
            chartRefs={chartRefs}
            showPopup={showPopup}
            handleChartTypeChange={handleChartTypeChange}
          />
        );
      }

      return null;
    }
  })();
  const chartDisplayType: DisplayType = Array.isArray(chartTabs) ? 'tabs' : chartTabs !== null ? 'single' : 'none';

  return (
    <div ref={parentRef}>
      {tableDisplayType === 'tabs' ? (
        <div>
          <FigureWithPreview showPopup={showPopup && showTable}>
            <Tabs
              activeTab={activeIndexTable}
              onTabChange={(index: number) => {
                setActiveIndexTable(index);
                if (index < (filteredDataCharts?.length ?? 0)) {
                  setActiveIndexChart(index);
                }
              }}
              tabs={tableTabs ?? []} // Pass generated table tabs
              source="table"
              renderContainer={renderChartDataContainer}
              showContainer={showTable}
              setShowContainer={setShowTable}
            />
          </FigureWithPreview>
        </div>
      ) : tableDisplayType === 'single' ? (
        <>
          <FigureWithPreview showPopup={showPopup && showTable}>
            {renderChartDataContainer(tableTabs as React.ReactNode, 'table', showTable, () => setShowTable(!showTable))}
          </FigureWithPreview>
        </>
      ) : null}

      {chartDisplayType === 'tabs' ? (
        <div>
          <FigureWithPreview showPopup={showPopup && showChart}>
            <Tabs
              activeTab={activeIndexChart}
              onTabChange={(index: number) => {
                setActiveIndexChart(index);
                if (index < (filteredDataCharts?.length ?? 0)) {
                  setActiveIndexTable(index);
                }
              }}
              tabs={chartTabs ?? []} // Pass generated chart tabs or an empty array as a default value
              source="chart"
              renderContainer={renderChartDataContainer}
              showContainer={showChart}
              setShowContainer={setShowChart}
            />
          </FigureWithPreview>
        </div>
      ) : chartDisplayType === 'single' ? (
        <div>
          <FigureWithPreview showPopup={showPopup && showChart}>
            {renderChartDataContainer(chartTabs as React.ReactNode, 'chart', showPopup ? true : showChart, () =>
              setShowChart(!showChart),
            )}
          </FigureWithPreview>
        </div>
      ) : null}
    </div>
  );
};

const FigureWithPreview: FC<{ children: React.ReactNode; showPopup: boolean }> = ({ children, showPopup }) => {
  return (
    <>
      {children}
      {showPopup && (
        <div className={styles.popupBG}>
          <div className="popup-chart">{children}</div>
        </div>
      )}
    </>
  );
};

export default RenderChartDataCard;
