import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { changeQueryNodeInChain } from '../../redux/features/conversationSlice';
import { RootState } from '../../redux/store';
import { getNodesOfType, sortNodeIdsByCreationTimeAsc } from '../../utils/conversation';
import UserAvatar from '../Core/userAvatar/UserAvatar';
import SuggestedQuestions from '../SuggestedQuestions/SuggestedQuestions';
import { VersionSwitcher } from '../VersionSwitcher';

import { MessageContent } from './MessageContent';
import { MessageEditableQuery } from './MessageEditableQuery';
import { MessageSkeletonLoader } from './MessageSkeletonLoader';

import 'react-toastify/dist/ReactToastify.css';
import styles from './Allmsgs.module.css';

const Allmsgs: React.FC = () => {
  const dispatch = useDispatch();
  const isConversationLoading = useSelector((state: RootState) => state.conversation.isConversationLoading);
  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);
  const conversationChain = useSelector((state: RootState) => state.conversation.conversationChain);
  const userEmail = useSelector((state: RootState) => state.user.userData?.email)?.toLowerCase();

  return (
    <>
      <ToastContainer />
      <div className={styles.allMsgs} data-testid="allmsgs-component" id="conversation-container">
        {isConversationLoading || !conversationTree ? (
          <MessageSkeletonLoader />
        ) : (
          conversationChain.map(({ queryNodeId, answerNodeId }, index) => {
            const siblingQueryNodes = getNodesOfType(
              conversationTree.nodes[queryNodeId].parent === conversationTree.conversation_id
                ? conversationTree.top_level_node_ids
                : conversationTree.nodes[conversationTree.nodes[queryNodeId].parent ?? ''].children,
              conversationTree,
              'query',
            );
            const siblingQueryNodesSorted = sortNodeIdsByCreationTimeAsc(
              siblingQueryNodes.map((node) => node.id),
              conversationTree,
            );
            const queryNodePosition = siblingQueryNodesSorted.findIndex((nodeId) => nodeId === queryNodeId);
            const nextQueryNode = siblingQueryNodesSorted[queryNodePosition + 1] || null;
            const prevQueryNode = siblingQueryNodesSorted[queryNodePosition - 1] || null;
            const isChatOwner = conversationTree.nodes[queryNodeId].data.user_email.toLowerCase() === userEmail;
            const nodeEmail = conversationTree.nodes[queryNodeId].data.user_email;
            return (
              <div className={styles.singleMsg} key={queryNodeId} id={`share-chat-${queryNodeId}`}>
                <div className={styles.msgHeader} id={`msgHeader-${queryNodeId}`}>
                  <div className={styles.user_image}>
                    <UserAvatar isChatOwner={isChatOwner} nodeEmail={nodeEmail} />
                  </div>
                  <div className={styles.editableQueryContainer}>
                    <MessageEditableQuery queryNodeId={queryNodeId} />
                    {siblingQueryNodesSorted.length > 1 && (
                      <div className={styles.versionContainer}>
                        <VersionSwitcher
                          current={queryNodePosition + 1}
                          total={siblingQueryNodesSorted.length}
                          isNextAvailable={!!nextQueryNode}
                          isPrevAvailable={!!prevQueryNode}
                          onNextClick={() => {
                            if (nextQueryNode) {
                              dispatch(
                                changeQueryNodeInChain({
                                  currentQueryNodeId: queryNodeId,
                                  newQueryNodeId: nextQueryNode,
                                }),
                              );
                            }
                          }}
                          onPrevClick={() => {
                            if (prevQueryNode) {
                              dispatch(
                                changeQueryNodeInChain({
                                  currentQueryNodeId: queryNodeId,
                                  newQueryNodeId: prevQueryNode,
                                }),
                              );
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <MessageContent queryNodeId={queryNodeId} answerNodeId={answerNodeId} />
                {index === conversationChain.length - 1 && <SuggestedQuestions answerNodeId={answerNodeId} />}
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default Allmsgs;
