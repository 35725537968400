import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { startTour } from '../../driverSetup'; // Import the startTour function from the JS file
import useAnalytics from '../../hooks/useAnalytics';
import { setRedirectTo } from '../../redux/features/landingPageSlice';
import { AppDispatch, RootState } from '../../redux/store';

import Notice from './NoticeComponent';
import QuestionBody from './QuestionBody';
import QuestionHeader from './QuestionHeader';
import QuestionSituation from './Situations';

import './OnboardingContainer.css';

const OnboardingContainer = () => {
  const [step, setStep] = useState(1); // State to track the current step of the onboarding process
  const navigate = useNavigate();
  const receipient = useSelector((state: RootState) => state.conversation.isReceipient);
  const redirectTo = useSelector((state: RootState) => state.landingPage.redirectTo);
  const dispatch = useDispatch<AppDispatch>();
  const onboardingRef = useRef<HTMLDivElement>(null);

  const suggestion2 = useRef<HTMLDivElement>(null);

  const suggestion3 = useRef<HTMLDivElement>(null);

  const suggestion4 = useRef<HTMLDivElement>(null);

  const analytics = useAnalytics();

  useEffect(() => {
    if (step === 1 && onboardingRef.current) {
      onboardingRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (step === 2 && suggestion2.current) {
      suggestion2.current.scrollIntoView({ behavior: 'smooth' });
    } else if (step > 2 && suggestion3.current) {
      suggestion3.current.scrollIntoView({ behavior: 'smooth' });
    } else if (step > 3 && suggestion4.current) {
      suggestion4.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [step]);

  // Function to handle moving to the next step
  const handleNextStep = () => {
    setStep(step + 1);
    analytics.trackOnboardingStep(step + 2);
  };

  const handleClick = () => {
    analytics.trackOnboardingComplete();
    localStorage.setItem('showOnboarding', 'complete');
    if (receipient) {
      if (redirectTo) {
        navigate(redirectTo);
      } else {
        navigate('/chat');
      }
      dispatch(setRedirectTo(''));
    } else {
      // pass this redirect url to the chat page to redirect them to share url
      navigate('/chat');
      setTimeout(() => {
        startTour();
      }, 2000);
    }
  };

  return (
    <div className="IC-Onboarding">
      {step >= 1 && (
        <>
          <div className="IC-Onboarding-questions-container">
            <div className="IC-Onboarding-questions-header">
              <div className="IC-Onboarding-questions-title">
                <p>Alright! You are almost ready!</p>
              </div>
              <div className="IC-Onboarding-questions-info">
                <p>
                  Let&apos;s quickly go through this example to have a better understanding on asking questions and how
                  to tweak them to improve the response.
                </p>
              </div>
            </div>

            <QuestionSituation
              title="Situation"
              info="Suppose you want to know about the most talked about brands in the US in the last 1 month."
            />

            <div className="IC-Onboarding-question">
              <QuestionHeader user="You" question="What most talk brands" />
              <QuestionBody
                user="Insights Copilot"
                message="The analysis of the most talked-about beer brands in recent months reveals a dynamic landscape shaped by consumer preferences, social media trends, and strategic corporate maneuvers. Brands like Corona, Budweiser, Bud Light, Modelo, and others have dominated conversations, reflecting their popularity and the effectiveness of their marketing strategies. Meanwhile, Anheuser-Busch InBev (AB InBev), the conglomerate behind many of these brands, navigates through market challenges and opportunities with strategic agility. The discourse around the most talked-about beer brands reveals a complex interplay between consumer engagement, social media dynamics, and strategic corporate decision-making. Brands like Corona, Budweiser, and Bud Light continue to capture the public's imagination, while AB InBev's strategic agility allows it to navigate market challenges effectively. The company's global presence, coupled with its financial health, underscores its capacity for resilience and growth in the ever-evolving beer industry."
              />
            </div>
          </div>

          <Notice
            header="Notice the question and its response?"
            info="Nothing useful ☹ The grammar and phrasing of the question is incorrect so the answer is not what we desired. The quality of response is directly dependent on how well the question is asked. Now let's improve the question and see what happens."
          />

          {step === 1 && (
            <div className="IC-Onboarding-Acknowledge-button" style={{ marginTop: '30px' }}>
              <button onClick={handleNextStep} style={{ cursor: 'pointer', gap: '16px' }}>
                Improve the question{' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                    d="M10.5104 6.86294C8.57091 6.45635 7.05494 4.94038 6.64841 3.00082C6.59847 2.76273 6.38847 2.59216 6.14513 2.59216C5.90178 2.59216 5.69178 2.76273 5.64188 3.00085C5.23531 4.94038 3.71934 6.45632 1.77981 6.86285C1.54169 6.91276 1.37109 7.12279 1.37109 7.3661C1.37109 7.60941 1.54166 7.81944 1.77981 7.86935C3.71931 8.27594 5.23522 9.79188 5.64178 11.7314C5.69169 11.9695 5.90169 12.1401 6.14503 12.1401C6.38834 12.1401 6.59838 11.9696 6.64828 11.7314C7.05488 9.79188 8.57088 8.27594 10.5104 7.86944C10.7485 7.81954 10.9191 7.60954 10.9191 7.36619C10.9191 7.12288 10.7485 6.91285 10.5104 6.86294Z"
                    fill="black"
                  />
                  <path
                    d="M14.2479 11.678C13.3341 11.4864 12.6199 10.7722 12.4283 9.8583C12.3784 9.62018 12.1684 9.44958 11.925 9.44958C11.6817 9.44958 11.4717 9.62012 11.4218 9.85827C11.2302 10.7721 10.5159 11.4864 9.60208 11.6779C9.36395 11.7278 9.19336 11.9379 9.19336 12.1812C9.19336 12.4245 9.36392 12.6345 9.60208 12.6844C10.5159 12.876 11.2301 13.5903 11.4217 14.5041C11.4716 14.7422 11.6816 14.9128 11.925 14.9128C12.1683 14.9128 12.3783 14.7423 12.4282 14.5041C12.6198 13.5903 13.3341 12.876 14.2479 12.6845C14.486 12.6346 14.6566 12.4245 14.6566 12.1812C14.6566 11.9379 14.4861 11.7279 14.2479 11.678Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          )}
        </>
      )}

      {step >= 2 && (
        <>
          <div className="IC-Onboarding-questions-container" ref={suggestion2}>
            <QuestionSituation
              title="Situation"
              info="Suppose you want to know about the most talked about brands in the US in the last 1 month."
            />

            <div className="IC-Onboarding-question">
              <QuestionHeader user="You" question="What <span>are the</span> most talked about brands?" />
              <QuestionBody
                user="Insights Copilot"
                message={
                  <>
                    <span>The most talked about brands are:</span>
                    <ul style={{ listStyle: 'number', paddingLeft: '14px' }}>
                      <li>Budweiser with a total of 6,064,508 mentions.</li>
                      <li>Bud Light with a total of 5,951,939 mentions.</li>
                      <li>Corona with a total of 3,637,911 mentions.</li>
                      <li>Michelob Ultra With a total Of 974,170 mentions.</li>
                      <li>Pilsen Callao with a total of 838,929 mentions.</li>
                      <li>Modelo with a total of 626,346 mentions.</li>
                      <li>Stella Artois with a total of 551,815 mentions.</li>
                      <li>Cusqueña with a total of 521,339 mentions.</li>
                      <li>Busch with a total of 262,110 mentions.</li>
                      <li>Atlas with a total of 250,672 mentions.</li>
                    </ul>
                  </>
                }
              />
            </div>
          </div>

          <Notice
            header="This is looking better!"
            info="We got some data this time. However, the response cannot be interpreted clearly as the question does not mention any geography or time period. Let's improve this question further with the missing specifics."
          />

          {step === 2 && (
            <div className="IC-Onboarding-Acknowledge-button" style={{ marginTop: '30px' }}>
              <button onClick={handleNextStep} style={{ cursor: 'pointer' }}>
                Improve Further
              </button>
            </div>
          )}
        </>
      )}

      {step >= 3 && (
        <>
          <div className="IC-Onboarding-questions-container" ref={suggestion3}>
            <QuestionSituation
              title="Situation"
              info="Suppose you want to know about the most talked about brands in the US in the last 1 month."
            />

            <div className="IC-Onboarding-question">
              <QuestionHeader
                user="You"
                question="What are the most talked about brands <span>in the US in the last 1 month?</span>"
              />
              <QuestionBody
                user="Insights Copilot"
                message={
                  <>
                    <span>
                      The most talked about brands <span>in the US in October 2023 are:</span>
                    </span>
                    <ul style={{ listStyle: 'number', paddingLeft: '14px' }}>
                      <li>
                        Bud Light with a total of <span>307,333 mentions</span>.
                      </li>
                      <li>
                        Budweiser with a total of <span>2,957,690 mentions</span>.
                      </li>
                      <li>
                        Michelob Ultra With a total Of <span>83,269 mentions</span>.
                      </li>
                      <li>
                        Corona with a total of <span>21,405 mentions</span>.
                      </li>
                      <li>
                        Stella Artois with a total of <span>11,759 mentions</span>.
                      </li>
                      <li>
                        Castle with a total of <span>949 mentions</span>.
                      </li>
                      <li>
                        Victoria with a total of <span>424 mentions</span>.
                      </li>
                      <li>
                        Pilsen Callao with a total of <span>290 mentions</span>.
                      </li>
                      <li>
                        Castle Larger with a total of <span>382 mentions</span>.
                      </li>
                      <li>
                        Club Larger with a total of <span>102 mentions</span>.
                      </li>
                    </ul>
                  </>
                }
              />
            </div>
          </div>

          <Notice
            header="Voila! You got it!"
            info="The answer now contains the data for the geography and time period you needed. See how changing the question directly improved the answer quality? You can get asking questions directly or try improving this question further."
          />
          {step === 3 && (
            <div className="IC-Onboarding-Acknowledge-button" style={{ marginTop: '30px', flexDirection: 'row' }}>
              <button
                style={{
                  background: 'var(--Secondary-Grey-600)',
                  color: ' var( --neutral-0)',
                  cursor: 'pointer',
                }}
                onClick={handleNextStep}
              >
                Improve Further
              </button>
              <button onClick={handleClick} style={{ cursor: 'pointer' }}>
                Start chatting
              </button>
            </div>
          )}
        </>
      )}

      {step >= 4 && (
        <>
          <div className="IC-Onboarding-questions-container" ref={suggestion4}>
            <QuestionSituation
              title="Situation"
              info="Suppose you want to know about the most talked about brands in the US in the last 1 month."
            />

            <div className="IC-Onboarding-question">
              <QuestionHeader
                user="You"
                question="What are the most talked about brands <span>owned by Heineken</span> in the US in the last 1 month?"
              />
              <QuestionBody
                user="Insights Copilot"
                message={
                  <>
                    <span className="no-data">No data found.</span>
                  </>
                }
              />
            </div>
          </div>

          <Notice
            header="What was that? 🧐"
            info="We asked for data that does not exist in AB Inbev’s scope. You can know about the scope of data by clicking on <span>Know your data.</span> "
          />
          <div className="final-info">Now you are ready to start chatting with Insights Copilot.</div>
          <div className="IC-Onboarding-Acknowledge-button" style={{ marginTop: '30px', flexDirection: 'row' }}>
            <button onClick={handleClick}>Start chatting</button>
          </div>
        </>
      )}
    </div>
  );
};

export default OnboardingContainer;
