import { differenceInMinutes } from 'date-fns';

import { ConversationState, UserEmails } from '../redux/features/conversationSlice';
import { ConversationTree, ConversationTreeNode } from '../services/apiService/definitions/types';

export const SERVER_UNAVAILABLE_TIMEOUT_MINS = 2;
export const ABANDONED_CHAT_TIMEOUT_MINS = 5;

export const sortNodeIdsByCreationTimeDesc = (nodeIds: string[], conversationTree: ConversationTree): string[] => {
  return nodeIds
    .slice()
    .sort(
      (a, b) =>
        new Date(conversationTree.nodes[b].data.created_at).valueOf() -
        new Date(conversationTree.nodes[a].data.created_at).valueOf(),
    );
};

export const sortNodeIdsByCreationTimeAsc = (nodeIds: string[], conversationTree: ConversationTree): string[] => {
  return nodeIds
    .slice()
    .sort(
      (a, b) =>
        new Date(conversationTree.nodes[a].data.created_at).valueOf() -
        new Date(conversationTree.nodes[b].data.created_at).valueOf(),
    );
};

export const getMostRecentlyCreatedNodeId = (nodeIds: string[], conversationTree: ConversationTree): string | null => {
  if (!nodeIds.length) {
    return null;
  }
  const nodeIdsSorted = sortNodeIdsByCreationTimeDesc(nodeIds, conversationTree);
  return nodeIdsSorted[0] ?? null;
};

export const getNodesOfType = (
  nodeIds: string[],
  conversationTree: ConversationTree,
  type: ConversationTreeNode['type'],
): ConversationTreeNode[] => {
  return nodeIds.map((nodeId) => conversationTree.nodes[nodeId]).filter((node) => node.data.type === type);
};

export const getChildrenOfType = (
  nodeId: string,
  conversationTree: ConversationTree,
  type: ConversationTreeNode['type'],
): ConversationTreeNode[] => {
  return getNodesOfType(conversationTree.nodes[nodeId].children, conversationTree, type);
};

export const createChainFromConversation = (
  conversationTree: ConversationTree,
): { chain: ConversationState['conversationChain']; emails: UserEmails['userEmails'] } => {
  const chain: ConversationState['conversationChain'] = [];
  const emails: UserEmails['userEmails'] = [];
  let currentQueryNodeId = getMostRecentlyCreatedNodeId(conversationTree.top_level_node_ids, conversationTree);
  while (currentQueryNodeId) {
    const childQueryNodes = getChildrenOfType(currentQueryNodeId, conversationTree, 'query');
    const childAnswerNodes = getChildrenOfType(currentQueryNodeId, conversationTree, 'answer');
    const latestChildAnswerNodeId = getMostRecentlyCreatedNodeId(
      childAnswerNodes.map((node) => node.id),
      conversationTree,
    );
    if (!latestChildAnswerNodeId) {
      console.error(`No answer found for query node ${currentQueryNodeId}. Breaking the chain`);
      break;
    }
    childAnswerNodes.forEach((node) => {
      if (node.data.user_email) {
        emails.push({
          email: node.data.user_email,
          image: '',
        });
      }
    });
    chain.push({
      queryNodeId: currentQueryNodeId,
      answerNodeId: latestChildAnswerNodeId,
    });
    const latestChildQueryNodeId = getMostRecentlyCreatedNodeId(
      childQueryNodes.map((node) => node.id),
      conversationTree,
    );
    currentQueryNodeId = latestChildQueryNodeId;
  }
  return { chain, emails };
};

export const breakChainAfterGivenNode = (
  queryNodeId: string,
  conversationChain: ConversationState['conversationChain'],
) => {
  const index = conversationChain.findIndex((chain) => chain.queryNodeId === queryNodeId);
  const newChain = index === -1 ? conversationChain.slice() : conversationChain.slice(0, index + 1);
  return newChain;
};

export const breakChainAtGivenNodeInclusive = (
  queryNodeId: string,
  conversationChain: ConversationState['conversationChain'],
) => {
  const index = conversationChain.findIndex((chain) => chain.queryNodeId === queryNodeId);
  const newChain = index === -1 ? conversationChain.slice() : conversationChain.slice(0, index);
  return newChain;
};

export const finishChainTillLeafAnswerNode = (
  conversationChain: ConversationState['conversationChain'],
  conversationTree: ConversationTree,
) => {
  if (!conversationChain.length) return [];
  const copy = conversationChain.slice();
  const lastQueryNodeInChain = copy[copy.length - 1];
  const childQueryNodesOfLastQueryNodeInChain = getChildrenOfType(
    lastQueryNodeInChain.queryNodeId,
    conversationTree,
    'query',
  );
  let currentQueryNodeId = getMostRecentlyCreatedNodeId(
    childQueryNodesOfLastQueryNodeInChain.map((node) => node.id),
    conversationTree,
  );
  while (currentQueryNodeId) {
    const childAnswerNodes = getChildrenOfType(currentQueryNodeId, conversationTree, 'answer');
    const latestChildAnswerNodeId = getMostRecentlyCreatedNodeId(
      childAnswerNodes.map((node) => node.id),
      conversationTree,
    );
    if (!latestChildAnswerNodeId) {
      console.error(`No answer found for query node ${currentQueryNodeId}. Breaking the chain`);
      break;
    }
    copy.push({
      queryNodeId: currentQueryNodeId,
      answerNodeId: latestChildAnswerNodeId,
    });
    const childQueryNodes = getChildrenOfType(currentQueryNodeId, conversationTree, 'query');
    const latestChildQueryNodeId = getMostRecentlyCreatedNodeId(
      childQueryNodes.map((node) => node.id),
      conversationTree,
    );
    currentQueryNodeId = latestChildQueryNodeId;
  }
  return copy;
};

export const getLatestChildQueryOfQueryNode = (
  queryNodeId: string,
  conversationTree: ConversationTree,
): ConversationTreeNode | null => {
  const queryNodes = getChildrenOfType(queryNodeId, conversationTree, 'query');
  const latestQueryNodeId = getMostRecentlyCreatedNodeId(
    queryNodes.map((node) => node.id),
    conversationTree,
  );
  return latestQueryNodeId ? conversationTree.nodes[latestQueryNodeId] : null;
};

export const getLatestAnswerNodeOfQueryNode = (
  queryNodeId: string,
  conversationTree: ConversationTree,
): ConversationTreeNode | null => {
  const answerNodes = getChildrenOfType(queryNodeId, conversationTree, 'answer');
  const latestAnswerNodeId = getMostRecentlyCreatedNodeId(
    answerNodes.map((node) => node.id),
    conversationTree,
  );
  return latestAnswerNodeId ? conversationTree.nodes[latestAnswerNodeId] : null;
};

const isTimedOut = (created_at: string, timeoutThresholdMins: number) => {
  const userTimezoneOffset = new Date().getTimezoneOffset();
  const diff = created_at
    ? differenceInMinutes(new Date(), new Date(new Date(created_at).getTime() - userTimezoneOffset * 60000))
    : 0;
  return diff > timeoutThresholdMins;
};

export const isServerUnavailable = (answerNode: ConversationTreeNode) => {
  const taskStatus = answerNode?.data.task_status?.trim();
  return (
    !answerNode?.data.engine_response?.response &&
    (!taskStatus || taskStatus === 'STARTED') &&
    isTimedOut(answerNode?.data.created_at, SERVER_UNAVAILABLE_TIMEOUT_MINS)
  );
};

export const isAbandonedAnswerNode = (answerNode: ConversationTreeNode) => {
  const taskStatus = answerNode?.data.task_status?.trim();
  return (
    !answerNode?.data.engine_response?.response &&
    (!taskStatus || taskStatus === 'INPROGRESS' || taskStatus === 'INPROGESS') &&
    isTimedOut(answerNode?.data.created_at, ABANDONED_CHAT_TIMEOUT_MINS)
  );
};

export const isInprogressAnswerNode = (answerNode: ConversationTreeNode) => {
  const taskStatus = answerNode?.data.task_status?.trim();
  return (
    answerNode &&
    !answerNode?.data.engine_response?.response &&
    (!taskStatus || taskStatus === 'INPROGRESS' || taskStatus === 'INPROGESS') &&
    !isTimedOut(answerNode?.data.created_at, ABANDONED_CHAT_TIMEOUT_MINS) &&
    // Either task should be in progress
    (taskStatus === 'INPROGRESS' ||
      taskStatus === 'INPROGESS' ||
      // Or task status may be empty but it should not be timed out
      (!taskStatus && !isTimedOut(answerNode?.data.created_at, SERVER_UNAVAILABLE_TIMEOUT_MINS)))
  );
};

export const findInprogressNodesInChain = (
  conversationChain: ConversationState['conversationChain'],
  conversationTree: ConversationTree,
): ConversationTreeNode[] => {
  const nodes = [];
  for (const { answerNodeId } of conversationChain) {
    const answerNode = conversationTree.nodes[answerNodeId];
    if (isInprogressAnswerNode(answerNode)) {
      nodes.push(answerNode);
    }
  }
  return nodes;
};
