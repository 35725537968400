const DownloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 15" fill="none">
      <mask
        id="mask0_13042_4445"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="15"
      >
        <rect y="0.5" width="14" height="14" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13042_4445)">
        <path
          className="svg-icon-path"
          d="M7.00016 9.83325L4.0835 6.91658L4.90016 6.07075L6.41683 7.58742V2.83325H7.5835V7.58742L9.10016 6.07075L9.91683 6.91658L7.00016 9.83325ZM3.50016 12.1666C3.17933 12.1666 2.90468 12.0523 2.6762 11.8239C2.44773 11.5954 2.3335 11.3208 2.3335 10.9999V9.24992H3.50016V10.9999H10.5002V9.24992H11.6668V10.9999C11.6668 11.3208 11.5526 11.5954 11.3241 11.8239C11.0956 12.0523 10.821 12.1666 10.5002 12.1666H3.50016Z"
          fill="#F3F3F3"
        />
      </g>
    </svg>
  );
};

const AccordionUpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 17" fill="none">
      <mask id="mask0_8173_293" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
        <rect y="0.5" width="16" height="16" fill="#D9D9D9"></rect>
      </mask>
      <g mask="url(#mask0_8173_293)">
        <path
          className="svg-icon-path"
          d="M8 10.7502L4 6.75023L4.93333 5.81689L8 8.88356L11.0667 5.81689L12 6.75023L8 10.7502Z"
          fill="white"
        ></path>
      </g>
    </svg>
  );
};

const AccordionDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 17" fill="none">
      <mask id="mask0_7232_122181" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
        <rect width="16" height="16" transform="matrix(1 0 0 -1 0 16.5)" fill="#D9D9D9"></rect>
      </mask>
      <g mask="url(#mask0_7232_122181)">
        <path
          className="svg-icon-path"
          d="M8 6.24977L4 10.2498L4.93333 11.1831L8 8.11644L11.0667 11.1831L12 10.2498L8 6.24977Z"
          fill="white"
        ></path>
      </g>
    </svg>
  );
};

const MaximizeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12 11" fill="none">
      <mask
        id="mask0_13042_5538"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="11"
      >
        <rect x="0.806396" y="0.306396" width="10.3871" height="10.3871" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13042_5538)">
        <path
          className="svg-icon-path"
          d="M2.10474 9.39506V5.93269H2.97033V7.92355L8.42355 2.47033H6.43269V1.60474H9.89506V5.0671H9.02947V3.07624L3.57624 8.52947H5.5671V9.39506H2.10474Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

const MinimizeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12 13" fill="none">
      <mask
        id="mask0_13042_2937"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="13"
      >
        <rect y="0.5" width="12" height="12" fill="white" />
      </mask>
      <g mask="url(#mask0_13042_2937)">
        <path
          className="svg-icon-path"
          d="M1.7 11.5L1 10.8L4.3 7.5H2V6.5H6V10.5H5V8.2L1.7 11.5ZM6 6.5V2.5H7V4.8L10.3 1.5L11 2.2L7.7 5.5H10V6.5H6Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

const ChartIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path className="svg-icon-path" d="M5.33341 5.99984H2.66675V13.3332H5.33341V5.99984Z" fill="white" />
      <path className="svg-icon-path" d="M13.3334 8.6665H10.6667V13.3332H13.3334V8.6665Z" fill="white" />
      <path className="svg-icon-path" d="M9.33341 2.6665H6.66675V13.3332H9.33341V2.6665Z" fill="white" />
    </svg>
  );
};

const TableIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
      <path
        className="svg-icon-path"
        d="M13.0001 2H3.00008C2.26675 2 1.66675 2.6 1.66675 3.33333V12.6667C1.66675 13.4 2.26675 14 3.00008 14H13.0001C13.7334 14 14.3334 13.4 14.3334 12.6667V3.33333C14.3334 2.6 13.7334 2 13.0001 2ZM13.0001 3.33333V5.33333H3.00008V3.33333H13.0001ZM9.66675 12.6667H6.33341V6.66667H9.66675V12.6667ZM3.00008 6.66667H5.00008V12.6667H3.00008V6.66667ZM11.0001 12.6667V6.66667H13.0001V12.6667H11.0001Z"
        fill="white"
      />
    </svg>
  );
};

export { AccordionDownIcon, AccordionUpIcon, ChartIcon, DownloadIcon, MaximizeIcon, MinimizeIcon, TableIcon };
