import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { differenceInDays, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useAnalytics from '../../../hooks/useAnalytics';
import { fetchAndLoadConversationChats, setConversationId } from '../../../redux/features/conversationSlice';
import { fetchChatHistory } from '../../../redux/features/historySlice';
import { AppDispatch, RootState } from '../../../redux/store';

import ChatEntry from './ChatEntry';
import HistoryLoader from './Loader';

import styles from './ChartEntry.module.css';

type Props = {
  chatInputRef: React.RefObject<HTMLTextAreaElement>;
};

const ChatHistoryContainer: React.FC<Props> = ({ chatInputRef }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();
  const { conversation_id } = useParams<{ conversation_id: string }>();

  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  const conversations = useSelector((state: RootState) => state.history.conversations);
  const searchQuery = useSelector((state: RootState) => state.sideBar.searchQuery);
  const historyFetched = useSelector((state: RootState) => state.history.historyFetch);
  const selectedProjectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const loading = useSelector((state: RootState) => state.history.loading);
  const [filteredChatHistory, setFilteredChatHistory] = useState(conversations);
  const [activeTab, setActiveTab] = useState('all');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    if (tab === 'all') {
      setFilteredChatHistory(conversations);
    } else if (tab === 'private') {
      setFilteredChatHistory(conversations.filter((entry) => entry.trace_id === null));
    } else if (tab === 'shared') {
      setFilteredChatHistory(conversations.filter((entry) => entry.trace_id !== null));
    }
  };
  const handleChatHistoryClick = async (conversationId: string) => {
    dispatch(setConversationId(conversationId));
    await dispatch(fetchAndLoadConversationChats({ conversationId })).then((result) => {
      if (result.type.endsWith('fulfilled')) {
        analytics.trackChatHistoryClick(conversationId);
        navigate(`/chat/${conversationId}`);
      }
    });
    setTimeout(() => {
      chatInputRef.current?.focus();
    }, 100);
  };

  // every time there is a change in projectkey, setActiveTab to 'all'
  useEffect(() => {
    setActiveTab('all');
  }, [selectedProjectKey]);

  const categorizeChatHistory = (entries: typeof conversations) => {
    const today: typeof conversations = [];
    const yesterday: typeof conversations = [];
    const past10Days: typeof conversations = [];
    const earlier: typeof conversations = [];

    const now = new Date();

    entries
      .filter((entry) => !!entry.name)
      .forEach((entry) => {
        const entryDate = parseISO(entry.updated_at); // assuming entry.timestamp is an ISO string
        const difference = differenceInDays(now, entryDate);
        if (difference === 0) {
          today.push(entry);
        } else if (difference === 1) {
          yesterday.push(entry);
        } else if (difference <= 10) {
          past10Days.push(entry);
        } else {
          earlier.push(entry);
        }
      });
    return { today, yesterday, past10Days, earlier };
  };

  const groupedChatHistory = categorizeChatHistory(filteredChatHistory);

  const renderChatEntries = (entries: typeof conversations) => {
    return entries.map((entry) => <ChatEntry key={entry.conversation_id} entry={entry} />);
  };

  useEffect(() => {
    if (!historyFetched) {
      dispatch(fetchChatHistory({ projectKey: selectedProjectKey }));
    }
  }, [historyFetched, selectedProjectKey]);

  useEffect(() => {
    if (conversation_id && conversation_id !== conversationId) {
      handleChatHistoryClick(conversation_id);
    }
  }, [conversation_id]);

  useEffect(() => {
    setFilteredChatHistory(
      conversations.filter((entry) => entry.name.toLowerCase().includes(searchQuery.toLowerCase())),
    );
  }, [conversations, searchQuery]);

  return (
    <>
      {loading === 'pending' ? (
        <HistoryLoader />
      ) : (
        <>
          <div className={styles.chat_history_containers}>
            <div className={styles.chat_history_tabs}>
              <div
                className={clsx(styles.chat_history_tab, styles.all, { [styles.active]: activeTab === 'all' })}
                onClick={() => handleTabClick('all')}
              >
                <p>All</p>
              </div>
              <div
                className={clsx(styles.chat_history_tab, { [styles.active]: activeTab === 'private' })}
                style={{ padding: '0px 18px' }}
                onClick={() => handleTabClick('private')}
              >
                <p>Private</p>
              </div>
              <div
                className={clsx(styles.chat_history_tab, { [styles.active]: activeTab === 'shared' })}
                style={{ padding: '0px 13px' }}
                onClick={() => handleTabClick('shared')}
              >
                <p style={{ display: 'inline-flex', gap: '4px', alignItems: 'center' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                      className="svg-icon-path"
                      d="M8.335 6.565C9.02 7.03 9.5 7.66 9.5 8.5V10H11.5V8.5C11.5 7.41 9.715 6.765 8.335 6.565Z"
                      fill="#818181"
                    />
                    <path
                      className="svg-icon-path"
                      d="M7.5 6C8.605 6 9.5 5.105 9.5 4C9.5 2.895 8.605 2 7.5 2C7.265 2 7.045 2.05 6.835 2.12C7.25 2.635 7.5 3.29 7.5 4C7.5 4.71 7.25 5.365 6.835 5.88C7.045 5.95 7.265 6 7.5 6Z"
                      fill="#818181"
                    />
                    <path
                      className="svg-icon-path"
                      d="M4.5 6C5.605 6 6.5 5.105 6.5 4C6.5 2.895 5.605 2 4.5 2C3.395 2 2.5 2.895 2.5 4C2.5 5.105 3.395 6 4.5 6ZM4.5 3C5.05 3 5.5 3.45 5.5 4C5.5 4.55 5.05 5 4.5 5C3.95 5 3.5 4.55 3.5 4C3.5 3.45 3.95 3 4.5 3Z"
                      fill="#818181"
                    />
                    <path
                      className="svg-icon-path"
                      d="M4.5 6.5C3.165 6.5 0.5 7.17 0.5 8.5V10H8.5V8.5C8.5 7.17 5.835 6.5 4.5 6.5ZM7.5 9H1.5V8.505C1.6 8.145 3.15 7.5 4.5 7.5C5.85 7.5 7.4 8.145 7.5 8.5V9Z"
                      fill="#818181"
                    />
                  </svg>
                  Shared
                </p>
              </div>
            </div>

            {groupedChatHistory.today.length > 0 && (
              <div>
                <div style={{ padding: '5px 5px 5px 10px' }}>Today</div>
                {renderChatEntries(groupedChatHistory.today)}
              </div>
            )}
            {groupedChatHistory.yesterday.length > 0 && (
              <div style={{ marginTop: '30px' }}>
                <div style={{ padding: '5px 5px 5px 10px' }}>Yesterday</div>
                {renderChatEntries(groupedChatHistory.yesterday)}
              </div>
            )}
            {groupedChatHistory.past10Days.length > 0 && (
              <div style={{ marginTop: '30px' }}>
                <div style={{ padding: '5px 5px 5px 10px' }}>Past 10 Days</div>
                {renderChatEntries(groupedChatHistory.past10Days)}
              </div>
            )}
            {groupedChatHistory.earlier.length > 0 && (
              <div style={{ marginTop: '30px' }}>
                <div style={{ padding: '5px 5px 5px 10px' }}>Last 30 days</div>
                {renderChatEntries(groupedChatHistory.earlier)}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ChatHistoryContainer;
