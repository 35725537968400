import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { startTour } from '../../driverSetup';
import useAnalytics from '../../hooks/useAnalytics';
import { resetConversationState } from '../../redux/features/conversationSlice';
import { setIsProfileOpen } from '../../redux/features/userSlice';
import { AppDispatch } from '../../redux/store';
import { stopRunningCalls } from '../../services/apiService/globals';

import './Profile.css';

type Props = {
  isCollapsed: boolean;
};

const ProfileContainer: React.FC<Props> = ({ isCollapsed }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const analytics = useAnalytics();

  const handleTutorial = () => {
    analytics.trackTutorialButtonClick();
    stopRunningCalls();
    navigate('/chat');
    dispatch(resetConversationState());
    startTour();
    dispatch(setIsProfileOpen(false));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      dispatch(setIsProfileOpen(false));
    }
  };

  const handleClick = () => {
    analytics.trackPromptGuideClick();
    navigate('/onboarding');
  };

  const handleTermsofUse = () => {
    navigate('/terms-of-use');
  };

  const handlePrivacyPolicy = () => {
    navigate('/privacy-policy');
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={clsx('profile-container', { collapsed: isCollapsed })}>
      <div className="profile-options" ref={menuRef}>
        <div
          className="profile-option"
          onClick={(e) => {
            e.stopPropagation();

            handleClick();
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14" fill="none">
            <mask id="mask0_3556_29276" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
              <rect width="14" height="14" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3556_29276)">
              <path
                className="svg-icon-path"
                d="M6.5916 11.6666L9.50826 5.97913H7.4666V2.33329L4.54993 8.02079H6.5916V11.6666ZM6.99993 12.8333C6.19298 12.8333 5.43465 12.6802 4.72493 12.3739C4.01521 12.0677 3.39785 11.652 2.87285 11.127C2.34785 10.602 1.93222 9.98468 1.62597 9.27496C1.31972 8.56524 1.1666 7.8069 1.1666 6.99996C1.1666 6.19301 1.31972 5.43468 1.62597 4.72496C1.93222 4.01524 2.34785 3.39788 2.87285 2.87288C3.39785 2.34788 4.01521 1.93225 4.72493 1.626C5.43465 1.31975 6.19298 1.16663 6.99993 1.16663C7.80687 1.16663 8.56521 1.31975 9.27493 1.626C9.98465 1.93225 10.602 2.34788 11.127 2.87288C11.652 3.39788 12.0676 4.01524 12.3739 4.72496C12.6801 5.43468 12.8333 6.19301 12.8333 6.99996C12.8333 7.8069 12.6801 8.56524 12.3739 9.27496C12.0676 9.98468 11.652 10.602 11.127 11.127C10.602 11.652 9.98465 12.0677 9.27493 12.3739C8.56521 12.6802 7.80687 12.8333 6.99993 12.8333ZM6.99993 11.6666C8.29298 11.6666 9.39403 11.2121 10.3031 10.3031C11.2121 9.39406 11.6666 8.29301 11.6666 6.99996C11.6666 5.7069 11.2121 4.60586 10.3031 3.69683C9.39403 2.78781 8.29298 2.33329 6.99993 2.33329C5.70687 2.33329 4.60583 2.78781 3.6968 3.69683C2.78778 4.60586 2.33326 5.7069 2.33326 6.99996C2.33326 8.29301 2.78778 9.39406 3.6968 10.3031C4.60583 11.2121 5.70687 11.6666 6.99993 11.6666Z"
                fill="white"
              />
            </g>
          </svg>{' '}
          Prompt Guide
        </div>
        <div className="profile-option" onClick={handleTutorial}>
          {' '}
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14" fill="none">
            <path
              className="svg-icon-path"
              d="M9.05078 9.75488L12.6943 7.35254L9.05078 4.9502V9.75488ZM9.87109 6.47363L11.2041 7.35254L9.87109 8.23144V6.47363Z"
              fill="white"
            />
            <path
              className="svg-icon-path"
              d="M7.07812 2.81014C6.94635 2.74665 5.03943 1.2002 2.05078 1.2002H1.64062V2.58896C0.770547 2.71854 0.188699 2.88722 0 2.93073V12.7997C0.286863 12.7609 3.16862 11.7394 6.90052 12.6723L7 12.6972L7.09948 12.6723C10.8356 11.7383 13.7119 12.7607 14 12.7997V2.93073C13.81 2.90546 10.839 1.92262 7.07812 2.81014ZM0.820312 11.7593V3.57527C1.09162 3.51449 1.36574 3.46223 1.64062 3.41868V11.0439H2.05078C2.93505 11.0439 3.82107 11.1991 4.6541 11.4923C3.37225 11.4058 2.0793 11.4946 0.820312 11.7593ZM6.58984 11.5135C5.36195 10.7517 3.91828 10.3047 2.46094 10.2336V2.03155C4.93011 2.16436 6.4636 3.40768 6.58984 3.47049V11.5135ZM13.1797 11.7593C11.2822 11.3604 9.30762 11.3604 7.41016 11.7593V3.57527C9.30429 3.15079 11.2855 3.15079 13.1797 3.57527V11.7593Z"
              fill="white"
            />
          </svg>
          Tutorial
        </div>
        <div
          className="profile-option"
          onClick={(e) => {
            e.stopPropagation();
            handleTermsofUse();
          }}
        >
          {' '}
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path
              className="svg-icon-path"
              d="M16.5 5.65125H9.75V7.15125H16.5V5.65125ZM16.5 11.6513H9.75V13.1513H16.5V11.6513ZM4.155 8.65125L1.5 5.99625L2.5575 4.93876L4.1475 6.52875L7.3275 3.34875L8.385 4.40626L4.155 8.65125ZM4.155 14.6513L1.5 11.9963L2.5575 10.9388L4.1475 12.5288L7.3275 9.34875L8.385 10.4063L4.155 14.6513Z"
              fill="white"
            />
          </svg>
          Terms of Use
        </div>
        <div
          className="profile-option"
          onClick={(e) => {
            e.stopPropagation();
            handlePrivacyPolicy();
          }}
        >
          {' '}
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path
              className="svg-icon-path"
              d="M9 0.75L2.25 3.75V8.25C2.25 12.4125 5.13 16.305 9 17.25C12.87 16.305 15.75 12.4125 15.75 8.25V3.75L9 0.75ZM14.25 8.25C14.25 9.6375 13.8675 10.9875 13.215 12.1575L12.1275 11.07C13.095 9.615 12.93 7.635 11.6475 6.3525C10.185 4.89 7.8075 4.89 6.345 6.3525C4.8825 7.815 4.8825 10.1925 6.345 11.655C7.6275 12.9375 9.6075 13.095 11.0625 12.135L12.3525 13.425C11.46 14.49 10.305 15.3075 9 15.705C5.985 14.7675 3.75 11.64 3.75 8.25V4.725L9 2.3925L14.25 4.725V8.25ZM9 11.25C7.755 11.25 6.75 10.245 6.75 9C6.75 7.755 7.755 6.75 9 6.75C10.245 6.75 11.25 7.755 11.25 9C11.25 10.245 10.245 11.25 9 11.25Z"
              fill="white"
            />
          </svg>
          Privacy Policy
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
