import React from 'react';

const ChevronLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 14 15" fill="none">
      <path
        d="M9.16039 4.8225L8.33789 4L4.83789 7.5L8.33789 11L9.16039 10.1775L6.48872 7.5L9.16039 4.8225Z"
        fill="white"
        className="svg-icon-path"
      />
    </svg>
  );
};

const ChevronRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 14 15" fill="none">
      <path
        d="M5.66039 4L4.83789 4.8225L7.50956 7.5L4.83789 10.1775L5.66039 11L9.16039 7.5L5.66039 4Z"
        fill="white"
        className="svg-icon-path"
      />
    </svg>
  );
};

export { ChevronLeft, ChevronRight };
