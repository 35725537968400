import { useMemo } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ModuleRegistry,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import './table.css';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface Props {
  result: Array<Record<string, any>>;
  columnsChangeList: string[]; // Columns to be colored
  thresholds?: { [key: string]: { T1: number; T2: number } }; // Thresholds per column
}

const GridExample: React.FC<Props> = ({ result, columnsChangeList, thresholds = {} }) => {
  if (!result || result.length === 0) {
    return <div>No data available</div>; // Handle missing data
  }

  // Dynamically extract column names from the first row of the result
  const columnNames = Object.keys(result[0]);

  // Generate column definitions dynamically
  const columnDefs: ColDef[] = useMemo(() => {
    return columnNames.map((col) => {
      const isChangeColumn = columnsChangeList.includes(col);

      return {
        field: col,
        headerName: col.replace(/_/g, ' ').toUpperCase(),
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        unSortIcon: true,
        valueParser: (params) => {
          // Parse values into numbers (removes commas and %)
          if (typeof params.newValue === 'string') {
            const parsedValue = parseFloat(params.newValue.replace(/,/g, '').replace('%', ''));
            return isNaN(parsedValue) ? params.newValue : parsedValue;
          }
          return params.newValue;
        },
        comparator: (valueA, valueB) => {
          const parseNumber = (value: any) => {
            // Parse value into a number, return NaN for invalid numbers
            return typeof value === 'number' ? value : parseFloat(value?.toString().replace(/,/g, '').replace('%', ''));
          };

          const numA = parseNumber(valueA);
          const numB = parseNumber(valueB);

          // Case 1: Both are numbers → Numeric sorting
          if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
          }

          // Case 2: Either is not a number → Treat as strings and sort lexicographically
          const stringA = valueA?.toString() || '';
          const stringB = valueB?.toString() || '';

          return stringA.localeCompare(stringB);
        },

        cellStyle: isChangeColumn
          ? (params) => {
              let value = params.value;
              const { T1 = 0, T2 = 0 } = thresholds[col] || {};

              // Parse value for threshold comparison
              if (typeof value === 'string') {
                value = parseFloat(value.replace(/,/g, '').replace('%', ''));
              }

              if (!isNaN(value)) {
                if (value > T1) return { color: 'var(--Secondary-Green-500)', fontWeight: 600 };
                if (value < T2) return { color: 'var(--Secondary-Red-600)', fontWeight: 600 };
              }
              return { color: 'inherit', fontWeight: 600 };
            }
          : undefined,
      };
    });
  }, [columnNames, columnsChangeList, thresholds]);

  // Prepare row data
  const rowData = useMemo(() => {
    return result.map((row) => {
      const rowObject: Record<string, unknown> = {};
      columnNames.forEach((col) => {
        const value = row[col];
        rowObject[col] = typeof value === 'number' ? value.toLocaleString() : value;
      });
      return rowObject;
    });
  }, [result, columnNames]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
    };
  }, []);

  const autoSizeStrategy = useMemo<
    SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy
  >(() => {
    return {
      type: 'fitGridWidth',
      defaultMinWidth: 100,
    };
  }, []);

  // Conditionally enable pagination based on row count
  const shouldPaginate = result.length >= 20;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ height: '100%', boxSizing: 'border-box' }}>
        <div className={'ag-theme-quartz'} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={shouldPaginate} // Conditional pagination
            paginationPageSize={20}
            domLayout={'autoHeight'}
            autoSizeStrategy={autoSizeStrategy}
            rowHeight={30}
            suppressRowHoverHighlight={true}
          />
        </div>
      </div>
    </div>
  );
};

export default GridExample;
