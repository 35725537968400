import React, { useEffect, useRef, useState } from 'react';

import { ChevronLeft, ChevronRight } from '../icons/TabChevrons';

import styles from './Tabs.module.css';

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: any;
  onTabChange: (index: number) => void;
  activeTab: number;
  source: 'table' | 'chart';
  renderContainer?: any;
  showContainer: boolean;
  setShowContainer: (showContainer: boolean) => void;
}

export const Tabs: React.FC<TabsProps> = ({
  activeTab,
  onTabChange,
  tabs,
  source,
  renderContainer,
  showContainer,
  setShowContainer,
}) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const tabsRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

  const updateArrows = () => {
    const tabs = tabsRef.current;
    if (!tabs) return;

    const { scrollLeft, scrollWidth, clientWidth } = tabs;

    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
  };

  // Check if tabs are scrollable based on container width and content width
  const updateScrollable = () => {
    if (tabsRef.current) {
      setIsScrollable(tabsRef.current.scrollWidth > tabsRef.current.clientWidth);
    }
  };

  useEffect(() => {
    updateScrollable();
    window.addEventListener('resize', updateScrollable);

    return () => window.removeEventListener('resize', updateScrollable);
  }, []);

  useEffect(() => {
    updateArrows();
  }, []);

  const handleTabClick = (index: number) => {
    onTabChange(index);
  };

  const scrollTabs = (direction: 'left' | 'right') => {
    if (tabsRef.current) {
      const scrollAmount = direction === 'left' ? -150 : 150;
      tabsRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
    updateArrows();
  };

  return (
    <>
      {renderContainer(
        <>
          <div className={styles.tabsContainer}>
            <div className={styles.tabsPanel}>
              {isScrollable && canScrollLeft && (
                <>
                  <button
                    className={`${styles.scrollButton} ${styles.scrollButtonLeft}`}
                    onClick={() => scrollTabs('left')}
                  >
                    <ChevronLeft />
                  </button>
                  <div className={`${styles.fade} ${styles.fadeLeft}`}></div>
                </>
              )}
              <div className={styles.tabsList} ref={tabsRef} onScroll={updateArrows}>
                {tabs.map((tab: Tab, index: number) => (
                  <button
                    key={index}
                    className={`${styles.tabButton} ${activeTab === index ? styles.tabButtonActive : ''}`}
                    onClick={() => handleTabClick(index)}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
              {isScrollable && canScrollRight && (
                <>
                  <div className={`${styles.fade} ${styles.fadeRight}`}></div>
                  <button
                    className={`${styles.scrollButton} ${styles.scrollButtonRight}`}
                    onClick={() => scrollTabs('right')}
                  >
                    <ChevronRight />
                  </button>
                </>
              )}
            </div>
            <div className={styles.tabContent}>{tabs[activeTab].content}</div>
          </div>
        </>,
        source,
        showContainer,
        () => setShowContainer(!showContainer),
      )}
    </>
  );
};
