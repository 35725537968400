import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { RootState } from '../../redux/store';
import {
  capitalizeAndReplaceUnderscore,
  configureCategoryAxis,
  configureLegend,
  configureValueAxis,
  // createScrollbar,
  createSeries,
  customizeSelectionRectangle,
  customizeZoomOutButton,
  generateColorScheme,
  hideElements,
} from '../../utils/utils';

import { ChartType } from './ChartComponent';
am4core.useTheme(am4themes_animated);

interface ChartProps {
  chartData: any;
  title?: string;
  xAxisTitle?: string;
  yAxisTitle?: string[];
  y1Title?: string;
  y2Title?: string;
  id?: string;
  y1AxisTitle?: string[];
  chartType: ChartType;
  chartRef?: React.RefObject<any>;
  showPopup?: boolean;
}

const ChartCore: React.FC<ChartProps> = ({
  chartData,
  xAxisTitle,
  yAxisTitle,
  y2Title,
  y1Title,
  id,
  y1AxisTitle,
  chartType,
  chartRef,
}) => {
  const showChartTooltip = useSelector((state: RootState) => state.conversation.showChartTooltip);
  const [flag, setFlag] = useState(false);
  const theme = useSelector((state: RootState) => state.user.theme);
  const hasNegative = chartData.some((it: { [s: string]: unknown } | ArrayLike<unknown>) => {
    const values = Object.values(it).map(Number); // Convert all values to numbers
    return values.some((value) => value < 0); // Check if any value is negative
  });
  const allNegative = chartData.every((it: ArrayLike<unknown> | { [s: string]: unknown }) => {
    const values = Object.values(it).map(Number);
    return values.every((value) => value < 0);
  });

  useEffect(() => {
    setFlag(showChartTooltip);
  }, [flag, chartData]);

  useEffect(() => {
    if (!chartType) {
      console.error('chartType is undefined!');
      return;
    }
    let chart: any;
    if (chartType === 'pie' || chartType === 'doughnut') {
      chart = am4core.create(id, am4charts.PieChart);
    } else {
      chart = am4core.create(id, am4charts.XYChart);
    }
    // Set the number formatter for the root object to handle large numbers with K, M, B suffixes
    chart.numberFormatter = new am4core.NumberFormatter();
    chart.numberFormatter.numberFormat = '#,###.a'; // Adding commas after thousands and no decimal places
    chart.numberFormatter.bigNumberPrefixes = [
      // { "number": 1e+3, "suffix": "K" },
      { number: 1e6, suffix: 'M' },
      { number: 1e9, suffix: 'B' },
    ];
    chart.numberFormatter.smallNumberPrefixes = []; // Do not use small number prefixes

    const colors = generateColorScheme();
    customizeZoomOutButton(chart);
    chart.data = chartData;
    if (chartType !== 'pie' && chartType !== 'doughnut') {
      const categoryAxis =
        chartType === 'vbar' ||
        chartType === 'stackedbar' ||
        chartType === 'line' ||
        chartType === 'area' ||
        chartType === 'stackedarea'
          ? chart.xAxes.push(new am4charts.CategoryAxis())
          : chart.yAxes.push(new am4charts.CategoryAxis());
      configureCategoryAxis(theme, categoryAxis, xAxisTitle ?? '', chartType, chart);

      const valueAxis =
        chartType === 'vbar' ||
        chartType === 'stackedbar' ||
        chartType === 'line' ||
        chartType === 'area' ||
        chartType === 'stackedarea'
          ? chart.yAxes.push(new am4charts.ValueAxis())
          : chart.xAxes.push(new am4charts.ValueAxis());
      configureValueAxis(theme, valueAxis, y1Title ?? '', chartType, false, hasNegative, allNegative);

      yAxisTitle?.forEach((yTitle, index) => {
        const color = am4core.color(colors[index % colors.length]);
        createSeries(theme, chart, chartType, yTitle, capitalizeAndReplaceUnderscore(yTitle), color, xAxisTitle ?? '');
      });

      if (y1AxisTitle && y1AxisTitle.length > 0) {
        const secondaryValueAxis =
          chartType === 'vbar' ||
          chartType === 'stackedbar' ||
          chartType === 'line' ||
          chartType === 'area' ||
          chartType === 'stackedarea'
            ? chart.yAxes.push(new am4charts.ValueAxis())
            : chart.xAxes.push(new am4charts.ValueAxis());
        configureValueAxis(theme, secondaryValueAxis, y2Title ?? '', chartType, true, hasNegative, allNegative);
        secondaryValueAxis.syncWithAxis = valueAxis; //syncing grids
        y1AxisTitle.forEach((y2Title, index) => {
          const newColors = colors.slice((yAxisTitle ?? []).length - 1);
          const color = am4core.color(newColors[(index + y1AxisTitle.length) % newColors.length]);
          createSeries(
            theme,
            chart,
            chartType,
            y2Title,
            capitalizeAndReplaceUnderscore(y2Title),
            color,
            xAxisTitle ?? '',
            secondaryValueAxis,
          );
        });
      }
    } else {
      const chartDataWithColors = chartData.map((item: any, index: number) => ({
        ...item,
        color: am4core.color(colors[index % colors.length]),
      }));

      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      chart.data = chartDataWithColors;
      const series = chart.series.push(new am4charts.PieSeries());
      series.dataFields.value = yAxisTitle?.[0] ?? '';
      series.dataFields.category = xAxisTitle;
      series.ticks.template.disabled = true;
      series.labels.template.disabled = true;
      series.slices.template.propertyFields.fill = 'color';
      series.slices.template.tooltipText = "[bold]{category}: [bold]{value.percent.formatNumber('#.#')}% ";
      series.name = capitalizeAndReplaceUnderscore(yAxisTitle?.[0] ?? '');
      if (series.tooltip) {
        series.tooltip.label.fontSize = 8;
      }
      if (chartType === 'doughnut') {
        chart.innerRadius = am4core.percent(60);
      }
    }

    if (chartRef && chartRef.current) {
      (chartRef.current as any).chart = chart;
      configureLegend(theme, chart, id, chartRef);
    }

    if (chartType !== 'pie' && chartType !== 'doughnut') {
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineX.stroke = am4core.color('#fff');
      chart.cursor.lineY.stroke = am4core.color('#fff');
      chart.cursor.maxTooltipDistance = 20;
      // if (chartType === 'hbar') {
      //   createScrollbar(theme, chart, 'y', am4core.color('#4C4C4C'), 4);
      // } else {
      //   createScrollbar(theme, chart, 'x', am4core.color('#4C4C4C'), 4);
      // }
    }
    chart.responsive.enabled = true;

    hideElements();
    customizeSelectionRectangle(chart);
    am4core.options.autoDispose = true;
  }, [chartData, chartType, id, theme]);

  return (
    <>
      <div id={id} style={{ height: '300px', zIndex: 1, position: 'relative' }} ref={chartRef}></div>
      <div
        id={`legend${id}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 'auto',
          overflow: 'hidden',
          position: 'relative',
        }}
      ></div>
    </>
  );
};

export default ChartCore;
