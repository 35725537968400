import React, { useEffect, useState } from 'react';

import { EngineChartResults } from '../../services/apiService/definitions/types';

import ChartCardControls from './ChartCardControls';
import ChartCore from './ChartCore';

import styles from './ChartComponent.module.css';

export type ChartType = 'line' | 'vbar' | 'hbar' | 'doughnut' | 'pie' | 'area' | 'stackedbar' | 'stackedarea';
interface CombinedChartProps {
  selectedChartType: ChartType;
  chartResults?: EngineChartResults | null;
  showPopup?: boolean;
  chartRefs: {
    line: React.RefObject<any>;
    vbar: React.RefObject<any>;
    hbar: React.RefObject<any>;
    doughnut: React.RefObject<any>;
    pie: React.RefObject<any>;
    area: React.RefObject<any>;
    stackedbar: React.RefObject<any>;
    stackedarea: React.RefObject<any>;
  };
  handleChartTypeChange: (type: ChartType) => void;
}

const ChartComponent: React.FC<CombinedChartProps> = ({
  selectedChartType,
  chartResults,
  showPopup,
  handleChartTypeChange,
  chartRefs,
}) => {
  const [uniqueId, setUniqueId] = useState('');

  // Generate a unique ID for the chart instance
  useEffect(() => {
    const generateUniqueId = () => {
      return 'id-' + Date.now() + '-' + Math.random().toString(36).substr(2, 9);
    };
    setUniqueId(generateUniqueId());
  }, []);

  // Chart title
  const title = chartResults?.chart_title;

  const chartDataFeed = chartResults?.chart_data;

  // Naming x-axis and y-axis titles
  const xAxisTitle = chartResults?.x_axis_cols[0]?.trim() || 'X-Axis';
  const yAxisTitle = chartResults?.y_axis_cols || [];
  const y1AxisTitle = chartResults?.y2_axis_cols;
  const y2Title = chartResults?.y2_axis_name || 'Secondary Axis';
  const y1Title = chartResults?.y_axis_name || 'Y-Axis';
  // A type guard to ensure chartType maps to a valid chart reference key
  function getChartRefKey(chartType?: ChartType): ChartType {
    return chartType ?? 'line';
  }
  // Get the correct chartRef based on the chartType
  const chartRefKey = selectedChartType ? getChartRefKey(selectedChartType) : null;
  const chartRef = chartRefKey ? chartRefs[chartRefKey] : undefined;
  return (
    <div
      className={styles.chartcard_wrapper}
      style={{
        maxWidth: showPopup ? '990px' : '100%',
        maxHeight: showPopup ? '80vh' : 'none',
        overflowY: showPopup ? 'auto' : 'hidden',
      }}
    >
      <div className={styles.chart_area_wapper}>
        <div className={styles.chart_area}>
          <div
            className="chartDiv"
            style={{
              display: 'block',
            }}
          >
            <div className="chart-tab-content">
              <div
                className={styles.graphArea}
                style={{
                  height: selectedChartType === 'pie' || selectedChartType === 'doughnut' ? '300px' : '',
                  // maxWidth: selectedChartType === 'pie' || selectedChartType === 'doughnut' ? '450px' : '',
                }}
              >
                {chartResults && (
                  <ChartCore
                    chartData={chartDataFeed}
                    title={title}
                    xAxisTitle={xAxisTitle}
                    yAxisTitle={yAxisTitle}
                    y1AxisTitle={y1AxisTitle}
                    y1Title={y1Title}
                    y2Title={y2Title}
                    id={uniqueId}
                    chartType={selectedChartType}
                    chartRef={chartRef}
                    showPopup={showPopup}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Chart controls rendering */}
        <ChartCardControls
          selectedChartType={selectedChartType ?? 'line'}
          chartResults={chartResults}
          handleChartTypeChange={(type: ChartType) => handleChartTypeChange(type)}
        />
      </div>
    </div>
  );
};

export default ChartComponent;
