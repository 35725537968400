import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import Discover from './components/DiscoverFlow/Discover';
import KnowYourData from './components/KnowYourDataFlow/KnowYourData';
import LandingScreen from './components/LandingScreen/LandingScreen';
import HomeLayout from './components/Layout/ChatLayout/ChatLayout';
import ShareConversationsLayout from './components/Layout/ShareConversationLayout/ShareConversationLayout';
import Allmsgs from './components/Messages/Allmsgs';
import PrivacyPolicy from './components/TermsofUse/PrivacyPolicy';
import TermsofUse from './components/TermsofUse/TermsofUse';
import Toast from './components/Toast/ToastComponent';
import useAnalytics from './hooks/useAnalytics';
import Login from './pages/Login';
import Resources from './pages/Resources';
import UserOnboarding from './pages/UserOnboarding';
import { fetchChatHistory } from './redux/features/historySlice';
import { setProjectKey, setProjectKeys } from './redux/features/sideBar';
import { setUserData } from './redux/features/userSlice';
import { AppDispatch, RootState } from './redux/store';
import { getUserProfile } from './services/apiService';
import PrivateRoutes from './ProtectedRoute';

import './App.css';
import './Responsive.css';

const AppLayout: React.FC = () => {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const theme = useSelector((state: RootState) => state.user.theme);
  const isForceLogin = pathname === '/login' && !!new URLSearchParams(search).get('force_relogin');
  const analytics = useAnalytics();
  useEffect(() => {
    // If user is in login page and force_relogin is truthy, don't fetch user profile. This is to prevent infinite loop
    if (isLoggedIn && !isForceLogin) {
      const fetchProfile = async () => {
        const {
          data: { username, image_bytes, user_groups, email },
        } = await getUserProfile();

        const userProjectKeys = user_groups.map((group) => group.project_name);
        let currentProjectKey = userProjectKeys[0]; // Default to the first project key
        try {
          // Fetch project keys from local storage, if available
          const projectKeyString = localStorage.getItem('ProjectKey');
          if (projectKeyString) {
            const projectKeyStringFromLocal = JSON.parse(projectKeyString);
            if (projectKeyStringFromLocal && userProjectKeys.includes(projectKeyStringFromLocal)) {
              currentProjectKey = projectKeyStringFromLocal;
            }
          }
          await dispatch(fetchChatHistory({ projectKey: currentProjectKey }));
        } catch (err) {
          console.error('Error fetching project key from local storage:', err);
        }
        dispatch(setProjectKeys(user_groups));
        dispatch(setProjectKey(currentProjectKey));
        dispatch(
          setUserData({
            username,
            project_keys: user_groups,
            currentProjectKey,
            email,
            image: image_bytes
              ? `data:image/jpeg;base64,${image_bytes}`
              : `${process.env.PUBLIC_URL}/images/user-image.png`,
          }),
        );

        const projectKeys = user_groups.map((group) => group.project_name);
        analytics.identifyUser(email, { project: projectKeys, $email: email });
      };
      fetchProfile().catch((error) => {
        console.error('Error fetching user profile:', error);
        toast.error('Error loading the application. Please reload and try again.');
      });
    }
  }, [isLoggedIn]);

  return (
    <div className={`App ${theme === 'light-theme' ? 'light-theme' : ''}`}>
      <Toast />
      <Outlet />
      <ToastContainer
        style={{ width: 'auto' }}
        toastStyle={{
          fontSize: 'var(--Info-14)',
          lineHeight: '1.5',
          padding: '15px',
        }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

const RootWrapper: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/terms-of-use" element={<TermsofUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/resource" element={<Resources />} />
            <Route path="/onboarding" element={<UserOnboarding />} />
            <Route path="/chat" element={<HomeLayout />}>
              <Route index element={<LandingScreen />} />
              <Route path="discover" element={<Discover />} />
              <Route path="knowdata" element={<KnowYourData />} />
              <Route path=":conversation_id" element={<Allmsgs />} />
            </Route>
            <Route path="/chat/share/:trace_id" element={<ShareConversationsLayout />}>
              <Route index element={<Allmsgs />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to={'/chat'} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RootWrapper;
