import React from 'react';
import { clsx } from 'clsx';

import styles from './Tooltip.module.css';

interface TooltipProps {
  children: React.ReactNode;
  tooltipText: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
  classname?: React.CSSProperties;
}

const Tooltip = ({ children, tooltipText, position = 'right', classname }: TooltipProps) => {
  return (
    <div className={styles.tooltip}>
      {children}
      <span className={clsx(styles.tooltiptext, styles[position])} style={classname}>
        {tooltipText}
      </span>
    </div>
  );
};

export default Tooltip;
