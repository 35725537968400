// Desc: Landing page slice for fetching FAQ, Discover Insights and Know your data
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as apiService from '../../services/apiService';
import { RootState } from '../store';

const initialState = {
  FAQQuestions: [],
  DiscoverInsights: [],
  discoverLoader: true,
  faqloader: false,
  knowyourdata: [],
  knowyourdataloader: true,
  redirectTo: '',
};

export const fetchFAQQuestions = createAsyncThunk(
  'fetch-faq-questions',
  async ({ projectKey }: { projectKey: string }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const defaultProjectKey = state.sideBar.projectKey;
      const effectiveProjectKey = projectKey || defaultProjectKey;
      const { data } = await apiService.getFAQQuestions(effectiveProjectKey);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchDiscoverInsights = createAsyncThunk(
  'fetch-discover-insights',
  async ({ projectKey }: { projectKey: string }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const defaultProjectKey = state.sideBar.projectKey;
      const effectiveProjectKey = projectKey || defaultProjectKey;
      const { data } = await apiService.getdiscoverInsights(effectiveProjectKey);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const fetchKnowYourData = createAsyncThunk(
  'fetch-know-your-data',
  async ({ projectKey }: { projectKey: string }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const defaultProjectKey = state.sideBar.projectKey;
      const effectiveProjectKey = projectKey || defaultProjectKey;
      const { data } = await apiService.getKnowYourData(effectiveProjectKey);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const LandingpageSlice = createSlice({
  name: 'landingPage',
  initialState,
  reducers: {
    setFAQQuestions: (state, action) => {
      state.FAQQuestions = action.payload;
    },
    setDiscoverInsights: (state, action) => {
      state.DiscoverInsights = action.payload;
    },
    setKnowYourData: (state, action) => {
      state.knowyourdata = action.payload;
    },
    clearAllLandingState: (state) => {
      state.FAQQuestions = [];
      state.DiscoverInsights = [];
      state.knowyourdata = [];
    },
    setRedirectTo: (state, action) => {
      state.redirectTo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFAQQuestions.fulfilled, (state, action) => {
      state.FAQQuestions = action.payload;
      state.faqloader = false;
    });
    builder.addCase(fetchFAQQuestions.pending, (state) => {
      state.faqloader = true;
    });
    builder.addCase(fetchFAQQuestions.rejected, (state) => {
      state.faqloader = false;
    });

    builder.addCase(fetchDiscoverInsights.fulfilled, (state, action) => {
      state.DiscoverInsights = action.payload;
    });
    builder.addCase(fetchDiscoverInsights.pending, (state) => {
      state.discoverLoader = true;
    });
    builder.addCase(fetchDiscoverInsights.rejected, (state) => {
      state.discoverLoader = false;
    });

    builder.addCase(fetchKnowYourData.fulfilled, (state, action) => {
      state.knowyourdata = action.payload;
    });
    builder.addCase(fetchKnowYourData.pending, (state) => {
      state.knowyourdataloader = true;
    });
    builder.addCase(fetchKnowYourData.rejected, (state) => {
      state.knowyourdataloader = false;
    });
  },
});

export const { setFAQQuestions, setDiscoverInsights, setKnowYourData, clearAllLandingState, setRedirectTo } =
  LandingpageSlice.actions;
export default LandingpageSlice.reducer;
